import { observer } from 'mobx-react-lite';
import FilterSilderComponent from '../../../../../components/FilterSlider/FilterSlider';
import useRootStore from '../../../../../hooks/useRootStore';

interface Props {}

const FilterToolbar = () => {
    const { visible } = useRootStore().visibleStore;
    return (
        <>
            {visible.FilterToolbar ? (
                <div className="container">
                    <FilterSilderComponent />
                </div>
            ) : null}
        </>
    );
};

export default observer(FilterToolbar);
