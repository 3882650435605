export const SkeletonData = [
    {
        width: 200,
        position: 'left',
    },
    {
        width: 220,
        position: 'left',
    },
    {
        width: 180,
        position: 'left',
    },
    {
        width: 250,
        position: 'right',
    },
    {
        width: 180,
        position: 'left',
    },
    {
        width: 240,
        position: 'right',
    },
    {
        width: 280,
        position: 'left',
    },
    {
        width: 180,
        position: 'left',
    },

    {
        width: 230,
        position: 'left',
    },
    {
        width: 260,
        position: 'right',
    },
];
