import { message } from 'antd';
import { observer } from 'mobx-react-lite';
import { BsFillShareFill } from 'react-icons/bs';
import { IoIosSettings } from 'react-icons/io';
import { MdAdminPanelSettings, MdGroup, MdImportExport } from 'react-icons/md';
import { RiFileCopyFill, RiUserAddFill } from 'react-icons/ri';
import { FaUserLock, FaUsers } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import AvatarUpload from '../../../components/AvatarUpload/AvatarUpload';
import Header from '../../../components/Header/Header';
import MenuItem from '../../../components/MenuItem/MenuItem';
import Text from '../../../components/Text/Text';
import { TMP_URL } from '../../../env';
import useRootStore from '../../../hooks/useRootStore';
import { ButtonComponent } from '../../../utils/button';
import styles from './ManagaChannel.module.css';
import { MdFolderSpecial } from 'react-icons/md';
import { HiClipboardDocumentCheck } from 'react-icons/hi2';
import { FaLayerGroup } from 'react-icons/fa';
import { BiSolidCopy } from 'react-icons/bi';
import { toJS } from 'mobx';

message.config({
    maxCount: 2,
});

const ManagaChannel = () => {
    const { channelData, getChannelBlockedUsers, getChannelUsers, adminId } = useRootStore().channelStore;
    const { toRouterManageCh, closeModal, closeRightSideBar } = useRootStore().routerStore;
    const { userChannelLeave, getPreviewData } = useRootStore().usersStore;
    const { user } = useRootStore().authStore;
    const { show } = useRootStore().visibleStore;
    const navigation = useNavigate();

    const PreviewChannelAvatar = () => {
        getPreviewData(channelData as any, user.id === channelData.adminId);
        show('previewModal');
    };

    const leaveChannel = () => {
        userChannelLeave(channelData.id, () => navigation('', { replace: true }));
        closeRightSideBar();
    };

    const OpenBlockUser = () => {
        toRouterManageCh('blockUser');
        getChannelBlockedUsers(channelData.hashId);
    };

    const OpenGroupMedias = () => {
        toRouterManageCh('groupMedias');
        // getChannelBlockedUsers(channelData.hashId);
    };
    const OpenGroupPolicy = () => {
        // message.info('Soon you will be able to 😊');
        toRouterManageCh('groupPolicyManager');
        // getChannelBlockedUsers(channelData.hashId);
    };
    const OpenGroupPolicy11 = () => {
        message.info('Soon you will be able to 😊');

        // toRouterManageCh('groupMedias');
        // getChannelBlockedUsers(channelData.hashId);
    };
    const OpenBrandGroup = () => {
        toRouterManageCh('brandGroupChat');
        // getChannelBlockedUsers(channelData.hashId);
    };

    const OpenAddAdminToGroup = () => {
        toRouterManageCh('addAdminToGroup');
    };

    const OpenJoinRequests = () => {
        toRouterManageCh('joinRequests');
    };

    const OpenImportExportData = () => {
        toRouterManageCh('importExportData');
    };

    const EditGroup = () => {
        toRouterManageCh('editChannel');
        getChannelUsers(channelData.hashId);
    };

    const copyChatLink = () => {
        navigator.clipboard.writeText(window.location.href);
        message.success('Chat invite link copied');
    };

    const truncatedText = text => {
        const words = text.split(' ');
        if (words.length > 35) {
            return words.slice(0, 30).join(' ') + '...';
        } else {
            return text;
        }
    };

    return (
        <div
            className={styles.container}
            // style={channelData.isPremium ? { backgroundColor: `${channelData.mainColor}` } : {}}
        >
            <Header
                leftIcon="arrowRight"
                text={channelData.name}
                onLeftIconPress={() => closeModal('right')}
                isPremium={channelData.isPremium}
                color={channelData.mainColor}
                colorPText={channelData.colorHighlight}
            />
            <AvatarUpload
                upload={false}
                style={{ margin: '10px auto', width: '90%' }}
                imageUrl={channelData?.avatar ? `${TMP_URL}/${channelData.avatar}` : ''}
                color={
                    channelData?.color
                        ? `linear-gradient(25deg, ${channelData.color.startsWith('#') ? channelData.color : '#' + channelData.color} 30%, #ddd 100%)`
                        : 'linear-gradient(#ddd, #666)'
                }
                onPreview={() => PreviewChannelAvatar()}
            />
            <div className={styles.description}>
                <Text
                    fontSize="12px"
                    style={{ width: '90%' }}
                    children={channelData.description ? truncatedText(channelData.description) : 'no description'}
                />
            </div>
            {!!channelData?.website?.length ? (
                <div className={styles.webSite}>
                    <a href={channelData.website} target="_blank" rel="noreferrer">
                        {channelData?.website}
                    </a>
                </div>
            ) : null}
            {adminId === user.id ? (
                <div className={styles.itemsRow}>
                    <MenuItem
                        icon={<FaUsers size={24} />}
                        title="Members"
                        onClick={() => toRouterManageCh('channelSetting')}
                    />
                    <MenuItem
                        icon={<BsFillShareFill size={24} />}
                        title="Share chat invite link"
                        onClick={copyChatLink}
                        right
                    />
                    <MenuItem icon={<MdGroup size={24} />} title="Blocked members" onClick={OpenBlockUser} />
                    <MenuItem icon={<MdFolderSpecial size={24} />} title="Group medias" onClick={OpenGroupMedias} />
                    <MenuItem icon={<FaUserLock size={24} />} title="Blocked members" onClick={OpenBlockUser} />
                    <MenuItem icon={<IoIosSettings size={24} />} title="Edit group" onClick={EditGroup} />
                    <MenuItem icon={<RiUserAddFill size={24} />} title="Join requests" onClick={OpenJoinRequests} />
                    {channelData.isPremium && (
                        <>
                            <MenuItem
                                icon={<HiClipboardDocumentCheck size={24} />}
                                title="Provide your own privacy policy"
                                onClick={OpenGroupPolicy}
                            />
                            <MenuItem
                                icon={<FaLayerGroup size={24} />}
                                title="Brand group chat"
                                onClick={OpenBrandGroup}
                            />
                            <MenuItem
                                icon={<MdAdminPanelSettings size={24} />}
                                title="Add admin to group"
                                onClick={OpenAddAdminToGroup}
                            />
                            <MenuItem
                                icon={<MdImportExport size={24} />}
                                title="Import/Export emails"
                                onClick={OpenImportExportData}
                            />
                        </>
                    )}
                </div>
            ) : (
                <div className={styles.items}>
                    <MenuItem
                        icon={<FaUsers size={24} />}
                        title="Members"
                        onClick={() => toRouterManageCh('channelSetting')}
                    />
                    <MenuItem
                        icon={<BsFillShareFill size={24} />}
                        title="Share chat invite link"
                        onClick={copyChatLink}
                        right
                    />
                    <MenuItem icon={<MdFolderSpecial size={24} />} title="Group medias" onClick={OpenGroupMedias} />
                    {adminId === user.id ? (
                        <MenuItem icon={<RiUserAddFill size={24} />} title="Join requests" onClick={OpenJoinRequests} />
                    ) : null}
                    {channelData.isPremium && (
                        <>
                            <MenuItem
                                icon={<HiClipboardDocumentCheck size={24} />}
                                title="Provide your own privacy policy"
                                onClick={OpenGroupPolicy}
                            />
                            <MenuItem
                                icon={<FaLayerGroup size={24} />}
                                title="Brans group chat"
                                onClick={OpenBrandGroup}
                            />
                            {adminId === user.id ? (
                                <MenuItem
                                    icon={<MdAdminPanelSettings size={24} />}
                                    title="Add admin to group"
                                    onClick={OpenGroupPolicy11}
                                />
                            ) : null}
                            <MenuItem
                                icon={<MdImportExport size={24} />}
                                title="Import/Export emails"
                                onClick={OpenImportExportData}
                            />
                        </>
                    )}
                </div>
            )}
            <div className={styles.qrCodeBox}>
                <img src={channelData.qrCode} alt="" loading="lazy" />
            </div>
            <div className={styles.bottomBox}>
                {adminId === user.id ? <Text fontSize="14px" children="You are admin" /> : null}
                {adminId === user.id ? (
                    <ButtonComponent
                        backColor="transparent"
                        text="change admin"
                        color="red"
                        clickMe={() => toRouterManageCh('newAdmin')}
                    />
                ) : (
                    <ButtonComponent backColor="transparent" text="leave group" color="red" clickMe={leaveChannel} />
                )}
            </div>
        </div>
    );
};

export default observer(ManagaChannel);
