import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Loading } from '../components/Loading/Loading';
import useRootStore from '../hooks/useRootStore';
import AuthLayout from '../screens/AuthLayout';
import ChangeLanguageView from '../screens/auth/change-language/ChangeLanguage';
import ForgotPasswordView from '../screens/auth/forgot-password/ForgotPassword';
import OtpVerify from '../screens/auth/otp-verify/OtpVerify';
import Login from '../screens/auth/sign-in/Login';
import SignUpSocial from '../screens/auth/sign-up-social/SignUpSocial';
import SignUp from '../screens/auth/sign-up/SignUp';
import WelcomeView from '../screens/auth/welcome/Welcome';
import Chat from '../screens/home/chat/Chat';
import ChatHashtag from '../screens/home/chat/ChatHashtag';
import ChatTag from '../screens/home/chat/ChatTag';
import EmptyScreen from '../screens/home/emptyScreen/EmptyScreen';

const HomeLayout = React.lazy(() => import('../screens/HomeLayout'));

function RootRouter() {
    const { session } = useRootStore().localStore;
    const { toRouterManageCh, openRightSideBar } = useRootStore().routerStore;
    const navigation = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!session.accessToken) {
            navigation('/auth/welcome');
        }
    }, [session.accessToken]);

    // useEffect(() => {
    //     if (false) {
    //         toRouterManageCh('joinToChannel');
    //     }
    //     console.log(location.pathname);
    // }, [location.pathname]);

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <React.Suspense fallback={<Loading />}>
                        <HomeLayout />
                    </React.Suspense>
                }
            >
                {/* <Route
                    path="/join"
                    element={
                        <React.Suspense fallback={<Loading />}>
                            <EmptyScreen text="Not found channel" />
                        </React.Suspense>
                    }
                /> */}
                <Route
                    path=":name"
                    element={
                        <React.Suspense fallback={<Loading />}>
                            <Chat />
                        </React.Suspense>
                    }
                />
                <Route path=":name/:hashTag" element={<ChatHashtag />} />
                <Route path=":name/chatat" element={<ChatTag />} />
            </Route>
            <Route path="/auth" element={<AuthLayout />}>
                <Route index path="welcome" element={<WelcomeView />} />
                <Route path="login" element={<Login />} />
                <Route path="sign-up" element={<SignUp />} />
                <Route path="otp-verify" element={<OtpVerify />} />
                <Route path="sign-up-social" element={<SignUpSocial />} />
                <Route path="changeLanguage" element={<ChangeLanguageView />} />
                <Route path="forgot-pass" element={<ForgotPasswordView />} />
            </Route>
        </Routes>
    );
}

export default observer(RootRouter);
