import { Switch, message } from 'antd';
import { observer } from 'mobx-react-lite';
import { BsFillShareFill } from 'react-icons/bs';
import AvatarUpload from '../../../components/AvatarUpload/AvatarUpload';
import Header from '../../../components/Header/Header';
import { Loading } from '../../../components/Loading/Loading';
import MenuItem from '../../../components/MenuItem/MenuItem';
import Text from '../../../components/Text/Text';
import { TMP_URL } from '../../../env';
import useRootStore from '../../../hooks/useRootStore';
import { ButtonComponent } from '../../../utils/button';
import styles from './JoinToChannel.module.css';
import { RiChatPrivateLine } from 'react-icons/ri';
import NewInput from '../../../components/NewInput/NewInput';
import { useCallback, useState } from 'react';

message.config({
    maxCount: 1,
});

const JoinToChannel = () => {
    const { channelData, isLoad } = useRootStore().channelStore;
    const { sendJoinRequest, isLoading } = useRootStore().channelProStore;
    const { closeModal } = useRootStore().routerStore;
    const { getPreviewData } = useRootStore().usersStore;
    const { user } = useRootStore().authStore;
    const { show } = useRootStore().visibleStore;
    const [password, setPassword] = useState('');

    const PreviewChannelAvatar = () => {
        getPreviewData(channelData as any, user.id === channelData.adminId);
        show('previewModal');
    };

    const copyChatLink = () => {
        navigator.clipboard.writeText(window.location.href);
        message.success('Chat invite link copied');
    };

    const handleSendJoinRequest = useCallback(() => {
        if (channelData.isPrivate) {
            if (password == channelData.password) {
                if (channelData.isPremium) {
                    show('privacyPolicy');
                    setPassword('');
                } else {
                    sendJoinRequest(channelData.hashId, user.id);
                    closeModal('left');
                    setPassword('');
                }
            } else {
                message.error('Password is incorrect');
            }
        } else {
            if (channelData.isPremium) {
                show('privacyPolicy');
            } else {
                sendJoinRequest(channelData.hashId, user.id);
                closeModal('left');
            }
        }
    }, [channelData, user, password]);

    if (isLoad) {
        return <Loading />;
    }

    return (
        <div className={styles.container}>
            <Header
                leftIcon="arrowRight"
                text={channelData.name}
                onLeftIconPress={() => closeModal('left')}
                isPremium={channelData.isPremium}
                color={channelData.mainColor}
                colorPText={channelData.colorHighlight}
            />
            <AvatarUpload
                upload={false}
                style={{ margin: '10px auto', width: '90%' }}
                imageUrl={channelData?.avatar ? `${TMP_URL}/${channelData.avatar}` : ''}
                color={
                    channelData?.color
                        ? `linear-gradient(25deg, ${channelData.color.startsWith('#') ? channelData.color : '#' + channelData.color} 30%, #ddd 100%)`
                        : 'linear-gradient(#ddd, #666)'
                }
                onPreview={() => PreviewChannelAvatar()}
            />
            <div className={styles.description}>
                <Text fontSize="12px" style={{ width: '90%' }} children={'no description'} />
            </div>
            {!!channelData?.website?.length ? (
                <div className={styles.webSite}>
                    <a href={channelData.website} target="_blank" rel="noreferrer">
                        {channelData?.website}
                    </a>
                </div>
            ) : null}

            <div className={styles.items}>
                <MenuItem
                    icon={<RiChatPrivateLine size={24} />}
                    title={`Group type: ${channelData.isPrivate ? 'Private' : 'Public'}`}
                    right={<Switch disabled value={channelData.isPrivate} />}
                />
                {channelData.isPrivate && (
                    <NewInput
                        onChange={e => setPassword(e)}
                        placeholder="Password"
                        margin="0 15px"
                        width="93%"
                        fontSize="15px"
                        value={password}
                    />
                )}
                <MenuItem
                    icon={<BsFillShareFill size={24} />}
                    title="Share chat invite link"
                    onClick={copyChatLink}
                    right
                />
            </div>
            <div className={styles.bottomBox}>
                <ButtonComponent
                    loadingType="bounce"
                    isLoading={isLoading}
                    width="90%"
                    text="+ Join Group Request"
                    clickMe={handleSendJoinRequest}
                />
            </div>
        </div>
    );
};

export default observer(JoinToChannel);
