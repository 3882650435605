import { message } from 'antd';
import { makeAutoObservable, runInAction } from 'mobx';
import APIs from '../../api/api';
import { Operation } from '../../utils/Operation';
import { AppRootStore } from '../store';
import { Channel, ChannelInitialState, ChannelPro, ChannelsUsersType, EmailsBackupType } from '../../types/channel';
import { saveAs } from 'file-saver';
import { debounce } from '../../helper/helper';

export default class ChannelProStore {
    rootStore: AppRootStore;

    constructor(rootStore: AppRootStore) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
    }

    isLoading: boolean = false;

    sendJoinRequestOperation = new Operation<any>({} as any);
    updateChannelBrandOperation = new Operation<Channel>({} as Channel);
    getJoinRequestsOperation = new Operation<ChannelsUsersType[]>([] as ChannelsUsersType[]);
    acceptJoinRequestOperation = new Operation<ChannelsUsersType>({} as ChannelsUsersType);
    rejectJoinRequestOperation = new Operation<ChannelsUsersType>({} as ChannelsUsersType);
    getEmailsBackupOperation = new Operation<EmailsBackupType[]>([] as EmailsBackupType[]);
    privatePolicyOperation = new Operation<{ privateGroupPolicyText: string; privateGroupPolicyFileLink: string }>(
        {} as { privateGroupPolicyText: string; privateGroupPolicyFileLink: string },
    );
    searchGlobalGroupOperation = new Operation<Channel[]>([] as Channel[]);
    acceptGroupPrivacyPolicyOperation = new Operation<{ userId: number; channelId: number }>(
        {} as { userId: number; channelId: number },
    );
    getChannelByHashIdOperation = new Operation<Channel>({} as Channel);

    channelDataForPrivacyPolicy: Channel = ChannelInitialState;

    brandLogo: boolean = false;

    joinRequestsData: ChannelsUsersType[] = [];

    worldGroups: Channel[] = [];

    updataBrandChannelState: ChannelPro = {} as ChannelPro;

    setUpdateBrandChannelState = (key: keyof ChannelPro, value: any) => {
        runInAction(() => {
            this.rootStore.channelStore.setUpdataChannel[key] = value as never;
            this.updataBrandChannelState[key] = value as never;
        });
    };

    toBrandloOrback = (key: string) => {
        runInAction(() => {
            if (key === 'logo') {
                this.brandLogo = true;
            }
            if (key === 'background') {
                this.brandLogo = false;
            }
        });
    };

    sendJoinRequest = async (hashId: string, userId: number) => {
        runInAction(() => {
            this.isLoading = true;
        });
        await this.sendJoinRequestOperation.run(() => APIs.channelsPro.sendJoinRequests(hashId, userId));
        runInAction(() => {
            if (this.sendJoinRequestOperation.isSuccess) {
                message.success('Join request has been sent to channel successfully');
            } else {
                message.error('Join request has not been sent to channel');
            }
        });

        runInAction(() => {
            this.isLoading = false;
        });
    };

    getChannelByHashIdForPrivacyPolicy = async (hashId: string) => {
        await this.getChannelByHashIdOperation.run(() => APIs.channels.getChannelByHashId(hashId));
        if (this.getChannelByHashIdOperation.isSuccess) {
            runInAction(() => {
                this.channelDataForPrivacyPolicy = this.getChannelByHashIdOperation.data;
            });
        }
    };

    onAcceptGroupPrivacyPolicy = async (userId: number, channelId: number, callback?: () => void) => {
        await this.acceptGroupPrivacyPolicyOperation.run(() =>
            APIs.channelsPro.acceptGroupPrivacyPolicy(userId, channelId),
        );
        if (this.acceptGroupPrivacyPolicyOperation.isSuccess) {
            callback && callback();
            this.rootStore.channelStore.getMyChannels();
        } else {
            message.error('Accept privacy policy something went wrong');
        }
    };

    brandDataToSetData = (channel: ChannelPro) =>
        (this.updataBrandChannelState = {
            isPremium: channel.isPremium,
            isPublic: channel.isPublic,
            logo: channel.logo,
            background: channel.background,
            mainColor: channel.mainColor,
            colorHighlight: channel.colorHighlight,
            website: channel.website,
            defaultHashtags: channel.defaultHashtags,
            privateGroupPolicyText: channel.privateGroupPolicyText,
            privateGroupPolicyFileLink: channel.privateGroupPolicyFileLink,
            minRelevanceOfText: channel.minRelevanceOfText,
            moderators: channel.moderators,
            qualityOfImage: channel.qualityOfImage,
        });

    updateBrandGroup = async (channel: Partial<Channel>, callback?: () => void) => {
        runInAction(() => {
            this.isLoading = true;
        });
        await this.updateChannelBrandOperation.run(() =>
            APIs.channelsPro.updateBrandChannel(this.rootStore.channelStore.channelData.hashId, channel as never),
        );

        if (this.updateChannelBrandOperation.isSuccess) {
            runInAction(() => {
                this.rootStore.channelStore.getMyChannels();
            });
            callback && callback();
        }
        runInAction(() => {
            this.isLoading = false;
        });
    };

    getJoinRequests = async (hashId: string) => {
        await this.getJoinRequestsOperation.run(() => APIs.channelsPro.getJoinRequests(hashId));
        runInAction(() => {
            if (this.getJoinRequestsOperation.isSuccess)
                this.joinRequestsData = Object.values(this.getJoinRequestsOperation.data);
        });
    };

    acceptJoinRequest = async (hashId: string, number: number) => {
        await this.acceptJoinRequestOperation.run(() => APIs.channelsPro.acceptJoinRequest(hashId, number));
        runInAction(() => {
            if (this.acceptJoinRequestOperation.isSuccess) {
                this.getJoinRequests(this.rootStore.channelStore.hashId);
                this.rootStore.channelStore.getChannelUsers(this.rootStore.channelStore.hashId);
            }
        });
    };

    rejectJoinRequest = async (hashId: string, userId: number) => {
        await this.rejectJoinRequestOperation.run(() => APIs.channelsPro.rejectJoinRequests(hashId, userId));
        runInAction(() => {
            if (this.rejectJoinRequestOperation.isSuccess) {
                this.getJoinRequests(this.rootStore.channelStore.hashId);
                this.rootStore.channelStore.getChannelUsers(this.rootStore.channelStore.hashId);
            }
        });
    };

    getEmailsBackup = async (hashId: string) => {
        await this.getEmailsBackupOperation.run(() => APIs.channelsPro.getEmailBackup(hashId));
        runInAction(() => {
            if (this.getEmailsBackupOperation.isSuccess) {
                const fileContent = this.getEmailsBackupOperation.data
                    .map(row => Object.values(row).join(', '))
                    .join('\n');
                const blob = new Blob([fileContent], { type: 'text/plain;charset=utf-8;' });
                saveAs(blob, 'data.txt');
                message.success('Emails have been downloaded successfully');
            }
        });
    };

    updatePrivacyPolicy = async (
        hashId: string,
        privateGroupPolicyText: string,
        privateGroupPolicyFileLink: string,
        callback?: () => void,
    ) => {
        runInAction(() => {
            this.isLoading = true;
        });
        await this.privatePolicyOperation.run(() =>
            APIs.channelsPro.updatePrivatePolicy(hashId, privateGroupPolicyText, privateGroupPolicyFileLink),
        );
        runInAction(() => {
            if (this.privatePolicyOperation.isSuccess) {
                message.success('Group private policy has been updated successfully');
                callback && callback();
            }
        });
        runInAction(() => {
            this.isLoading = false;
        });
    };

    searchGroupWorld = debounce(async (name: string) => {
        runInAction(() => {
            this.isLoading = true;
        });

        if (!!name && name?.length > 0) {
            await this.searchGlobalGroupOperation.run(() => APIs.channelsPro.searchGlobalGroups(name));

            runInAction(() => {
                if (this.searchGlobalGroupOperation.isSuccess) {
                    // @ts-ignore
                    this.worldGroups = this.searchGlobalGroupOperation.data?.data;
                }
            });
        }

        runInAction(() => {
            this.isLoading = false;
        });
    }, 1000);

    clearGlobalGroup = () => {
        runInAction(() => {
            this.worldGroups = [];
        });
    };
}
