import React from 'react';
import Header from '../../../components/Header/Header';
import useRootStore from '../../../hooks/useRootStore';
import { useTranslation } from 'react-i18next';
import MenuItem from '../../../components/MenuItem/MenuItem';
import { BiExport, BiImport } from 'react-icons/bi';
import styles from './ImportExportData.module.css';
import { FiFile } from 'react-icons/fi';

const ImportExportData = () => {
    const { t } = useTranslation();
    const { closeModal } = useRootStore().routerStore;
    const { channelData, hashId } = useRootStore().channelStore;
    const { getEmailsBackup } = useRootStore().channelProStore;

    return (
        <>
            <Header
                leftIcon="arrowLeft"
                text={t('import_export')}
                onLeftIconPress={() => closeModal('right')}
                color={channelData.mainColor}
                isPremium={channelData.isPremium}
                colorPText={channelData.colorHighlight}
            />
            <div className={styles.settings}>
                <MenuItem
                    icon={<FiFile size={24} />}
                    title="Import emails"
                    right={<BiImport size={24} />}
                    // onClick={OpenAddAdminToGroup}
                />
                <MenuItem
                    icon={<FiFile size={24} />}
                    title="Export emails"
                    right={<BiExport size={24} />}
                    onClick={() => getEmailsBackup(hashId)}
                />
            </div>
        </>
    );
};

export default ImportExportData;
