import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { RawMessage, TMediaList, TMediaMessage, ToneToOne } from '../../types/channel';
import { AppRootStore } from '../store';
import _ from 'lodash';

export const initialMediaList = {
    messages: [],
    pageState: '',
    end: false,
};

class ChannelSocketStore {
    root: AppRootStore;
    props: any;

    mediaList: TMediaList = initialMediaList;
    mediaLoading: boolean = true;
    isOpenTagScreen: boolean = false;
    oneToOneUser: ToneToOne[] = [];

    tagsMessages: {
        messages: RawMessage[];
        pageState: string | null;
        end?: boolean;
    } = {
        messages: [],
        pageState: null,
        end: false,
    };

    constructor(root: AppRootStore) {
        makeAutoObservable(this);
        this.root = root;
    }

    getMessageMedia = (dto: TMediaMessage) => {
        if (this.root.messageStore.slug) {
            this.root.socketStore.socket?.emit('media', dto);
        }
    };

    visible = {
        image: true,
        audio: false,
        video: false,
        document: false,
    };

    setTagsMessages = (data: { messages: RawMessage[]; pageState: string; end: boolean }) => {
        if (!!this.tagsMessages.messages) {
            if (data.pageState !== null && this.tagsMessages.pageState === data.pageState) {
                return;
            } else {
                this.tagsMessages.messages = [
                    ...(this.tagsMessages.messages.reverse().filter(item => item?.id === data.messages[0]?.id)
                        .length === 1
                        ? []
                        : data.messages),
                    ...this.tagsMessages.messages,
                ];
                this.tagsMessages.pageState = data.pageState;
                this.tagsMessages.end = data.end;
            }
        } else {
            runInAction(() => {
                this.tagsMessages = data;
            });
        }
    };

    addMessageTags = (message: RawMessage) => {
        if (this.tagsMessages.messages.length === 0) {
            this.tagsMessages.messages.push(message);
            // console.log('this.tagsMessages.messages', toJS(this.tagsMessages.messages));
        } else {
            if (_.last(this.tagsMessages.messages)?.id === message.id) {
                runInAction(() => {
                    this.tagsMessages.messages.pop();
                    this.tagsMessages.messages.push(message);
                });
            } else {
                this.tagsMessages.messages.push(message);
            }
        }
    };

    exitTagMessages = () => {
        this.clearTagMessages();
        this.oneToOneUser = [];
        this.setIsOpenTagScreen(false);
    };

    clearTagMessages = () => {
        runInAction(() => {
            this.tagsMessages = {
                messages: [],
                pageState: null,
                end: false,
            };
        });
    };

    setMediaMessageList = (data: TMediaList) => {
        if (this.mediaList?.pageState === data.pageState) {
            return;
        } else {
            this.mediaList.messages = [
                ...this.mediaList.messages,
                ...(this.mediaList.messages.filter(item => item.id === data.messages[0]?.id).length === 1
                    ? []
                    : [...data.messages]),
            ];
            this.mediaList.pageState = data.pageState;
            this.mediaList.end = data.end;
        }
    };

    init = () => {
        this.root.socketStore.socket?.on('getNewGroup', (payload: any) => {
            const newChannel = { ...payload, isNew: true };
            console.log('getNewGroup', toJS(newChannel));
            runInAction(() => {
                this.root.channelStore.myChannels = [newChannel, ...this.root.channelStore.myChannels];
            });
        });
    };

    setMediaLoading = (value: boolean) => {
        this.mediaLoading = value;
    };

    setOneToOne = (data: ToneToOne) => {
        if (this.oneToOneUser.length) {
            if (this.oneToOneUser.every(item => item.id !== data.id)) {
                runInAction(() => {
                    this.oneToOneUser = [...this.oneToOneUser, data];
                });
            }
        } else {
            runInAction(() => {
                this.oneToOneUser = [data];
            });
        }
    };
    removeTag = (id: number) => {
        runInAction(() => {
            this.oneToOneUser = this.oneToOneUser.filter(item => item.id !== id);
        });
    };

    setIsOpenTagScreen = (isOpen: boolean) => {
        this.isOpenTagScreen = isOpen;
    };

    toglevisible = (key: keyof typeof this.visible) => {
        for (const value in this.visible) {
            this.visible[value] = false;
        }
        this.visible[key] = true;
    };
}

export default ChannelSocketStore;
