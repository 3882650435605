import { Dropdown, MenuProps, Space, message as antMessage } from 'antd';
import { t } from 'i18next';
import { observer } from 'mobx-react-lite';
import { styled } from 'styled-components';
import useRootStore from '../../../hooks/useRootStore';
import { ChannelsUsersType, RawMessage } from '../../../types/channel';

//

interface Props {
    massage: RawMessage;
    users?: {
        [key: string]: ChannelsUsersType;
    };
    children: React.ReactNode;
}

const DropDownMenu = ({ massage, children }: Props) => {
    const { user } = useRootStore().authStore;
    const { isSelectChannelIsAdmin } = useRootStore().channelStore;
    const { deleteMessage, setEditMessage, setAddHashtag, replyMessage } = useRootStore().messageStore;
    const { reportMessage } = useRootStore().chatStore;

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: t('reply'),
            onClick: () => {
                replyMessage(massage);
            },
        },
        {
            key: '2',
            label: t('report'),
            onClick: () => {
                reportMessage({
                    channelSlug: massage.channelSlug,
                    messageId: massage.id,
                    report: 'Report Message',
                    timestamp: massage.timestamp,
                    userId: user.id,
                });
                antMessage.info('Reported Message!');
            },
        },
        {
            key: '3',
            label: t('copy'),
            onClick: () => {
                try {
                    navigator.clipboard.writeText(massage.message);
                    antMessage.info('Copied!');
                } catch (err) {
                    console.log('Failed to copy!');
                    antMessage.error('Copied!');
                }
            },
        },
        {
            key: '4',
            label: t('Edit'),
            onClick: () => {
                if (massage.userId === user?.id) {
                    setEditMessage(massage);
                } else {
                    antMessage.error('You are not admin');
                }
            },
        },
        {
            key: '5',
            label: t('addHashtag'),
            onClick: () => {
                if (massage.userId === user?.id) {
                    setAddHashtag(massage);
                } else {
                    antMessage.error('You are not admin');
                }
            },
        },
        {
            key: '6',
            label: t('delete'),
            onClick: () => {
                if (massage.userId === user?.id) {
                    deleteMessage(massage.id, massage.channelSlug, new Date(massage.timestamp));
                } else {
                    antMessage.error('You are not admin');
                }
            },
        },
        {
            key: '7',
            label: t('cancel'),
            onClick: () => {},
        },
    ];

    const itemsIsAdmin: MenuProps['items'] = [
        {
            key: '1',
            label: t('reply'),
            onClick: () => {
                replyMessage(massage);
            },
        },
        {
            key: '2',
            label: t('copy'),
            onClick: () => {
                try {
                    navigator.clipboard.writeText(massage.message);
                    antMessage.info('Copied!');
                } catch (err) {
                    console.log('Failed to copy!');
                    antMessage.error('Copied!');
                }
            },
        },
        {
            key: '3',
            label: t('Edit'),
            onClick: () => {
                if (isSelectChannelIsAdmin || massage.userId === user?.id) {
                    setEditMessage(massage);
                } else {
                    antMessage.error('You are not admin');
                }
            },
        },
        {
            key: '4',
            label: t('addHashtag'),
            onClick: () => {
                if (isSelectChannelIsAdmin || massage.userId === user?.id) {
                    setAddHashtag(massage);
                } else {
                    antMessage.error('You are not admin');
                }
            },
        },
        {
            key: '5',
            label: t('delete'),
            onClick: () => {
                if (isSelectChannelIsAdmin || massage.userId === user?.id) {
                    deleteMessage(massage.id, massage.channelSlug, new Date(massage.timestamp));
                    antMessage.success('Message deleted successfully');
                } else {
                    antMessage.error('You are not admin');
                }
            },
        },
        {
            key: '6',
            label: t('cancel'),
            onClick: () => {},
        },
    ];

    return (
        <DropdownRN
            menu={{ items: !isSelectChannelIsAdmin ? items : itemsIsAdmin }}
            trigger={['contextMenu']}
            overlayStyle={{
                width: '150px',
                height: '200px',
                borderRadius: '10%',
                alignItems: 'center',
                padding: '15px',
            }}
        >
            <Space>{children}</Space>
        </DropdownRN>
    );
};

export default observer(DropDownMenu);

const DropdownRN = styled(Dropdown)`
    display: block;
`;
