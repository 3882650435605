import React, { useCallback } from 'react';
import styles from './addhashtags.module.css';
import { CloseIcon, PlusIcon } from '../../utils/icons';
import Colors from '../../utils/colors';
import useRootStore from '../../hooks/useRootStore';
import { observer } from 'mobx-react-lite';
import { message } from 'antd';

interface Props {}

const AddHashtags = ({}) => {
    const [hashtag, setHashtag] = React.useState<string>('');
    const { setHashTags } = useRootStore().hashtagStore;
    const { toglevisible, visible, hide } = useRootStore().visibleStore;

    const handleOpenHashtags = useCallback(() => {
        toglevisible('openHashtag');
    }, [visible.openHashtag]);

    const addHashtag = () => {
        setHashTags(hashtag.replaceAll('#', ''));
        setHashtag('');
        toglevisible('openHashtag');
        hide('openFooterMediaBar');
    };

    const onSendEnter = (e: any) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            addHashtag();
            e.preventDefault();
        }
    };

    const handleHashtage = (e: string) => {
        setHashtag(e);
    };

    return (
        <>
            {visible.openHashtag ? (
                <div className={styles.hashtag}>
                    <input
                        type="text"
                        onKeyDown={e => onSendEnter(e)}
                        className={styles.hashtaginput}
                        onChange={e => handleHashtage(e.target.value)}
                        value={hashtag}
                        placeholder="Create hashtag"
                    />

                    <div className={styles.hashtagIcons}>
                        <span onClick={() => addHashtag()}>
                            <PlusIcon size={24} color={Colors.GullGray} />
                        </span>
                        <span onClick={() => handleOpenHashtags()}>
                            <CloseIcon size={24} color={Colors.GullGray} />
                        </span>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default observer(AddHashtags);
