import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { IoIosArrowForward, IoIosColorPalette, IoLogoBuffer } from 'react-icons/io';
import { IoLogoTableau } from 'react-icons/io5';
import { MdBorderColor } from 'react-icons/md';
import Header from '../../../components/Header/Header';
import MenuItem from '../../../components/MenuItem/MenuItem';
import useRootStore from '../../../hooks/useRootStore';
import styles from './BrandGroupManager.module.css';
import NewInput from '../../../components/NewInput/NewInput';
import { ButtonComponent } from '../../../utils/button';
import { useState } from 'react';
import { message } from 'antd';
import { FaHashtag, FaLink } from 'react-icons/fa';
import { toJS } from 'mobx';

const BrandGroupChat = () => {
    const { t } = useTranslation();
    const { channelData } = useRootStore().channelStore;
    const { setUpdateBrandChannelState, updataBrandChannelState, updateBrandGroup, toBrandloOrback, isLoading } =
        useRootStore().channelProStore;
    const [hashtags, setHashtags] = useState(
        updataBrandChannelState.defaultHashtags?.length
            ? updataBrandChannelState.defaultHashtags?.map(item => `#${item}`).join(', ')
            : '',
    );
    const { closeModal, toRouterManageCh } = useRootStore().routerStore;
    const { user } = useRootStore().authStore;
    console.log('hashtags', toJS(hashtags));
    console.log('updataBrandChannelState', toJS(updataBrandChannelState.defaultHashtags));

    const isAdmin = channelData.adminId === user.id;

    const handleSave = () => {
        let newHashtags = hashtags
            ?.replaceAll(',', '')
            .replaceAll('#', '')
            .trim()
            .replace(/\s+/g, ' ')
            .split(' ')
            .map(hash => {
                return hash;
            });
        setUpdateBrandChannelState('defaultHashtags', newHashtags);
        updateBrandGroup(updataBrandChannelState, () => closeModal('right'));
        message.info('Group Brand updated successfully');
    };

    const handleBack = () => {
        setUpdateBrandChannelState('mainColor', channelData.mainColor);
        setUpdateBrandChannelState('colorHighlight', channelData.colorHighlight);
        setUpdateBrandChannelState('website', channelData.website);
        setUpdateBrandChannelState('defaultHashtags', channelData.defaultHashtags);
        setUpdateBrandChannelState('logo', channelData.logo);
        setUpdateBrandChannelState('background', channelData.background);
        closeModal('right');
    };

    const handleHashtags = (e: string) => {
        setHashtags(e);
    };

    const toBrandChatLogo = (key: string) => {
        toRouterManageCh('brandGroupLogoAndBack');
        toBrandloOrback(key);
    };

    return (
        <>
            <Header
                text={`${t('brandGroup')}`}
                textWeight={600}
                leftIcon="arrowLeft"
                onLeftIconPress={handleBack}
                color={channelData.mainColor}
                isPremium={channelData.isPremium}
                colorPText={channelData.colorHighlight}
            />
            <div className={styles.settings}>
                <MenuItem
                    icon={<IoLogoTableau size={24} />}
                    title={`Logo`}
                    right={<IoIosArrowForward size={22} />}
                    onClick={() => toBrandChatLogo('logo')}
                />

                <MenuItem
                    icon={<IoLogoBuffer size={24} />}
                    title={`Background`}
                    right={<IoIosArrowForward size={22} />}
                    onClick={() => toBrandChatLogo('background')}
                />
                <MenuItem
                    icon={<IoIosColorPalette size={24} />}
                    title={`Main Colour`}
                    right={
                        <input
                            value={updataBrandChannelState.mainColor}
                            type="color"
                            className={styles.colorUser}
                            disabled={!isAdmin}
                            onChange={e => setUpdateBrandChannelState('mainColor', e.target.value)}
                        />
                    }
                />
                <MenuItem
                    icon={<MdBorderColor size={20} />}
                    title={`Colour Highlight`}
                    right={
                        <input
                            value={updataBrandChannelState.colorHighlight}
                            type="color"
                            className={styles.colorUser}
                            disabled={!isAdmin}
                            onChange={e => setUpdateBrandChannelState('colorHighlight', e.target.value)}
                        />
                    }
                />
                <MenuItem icon={<FaLink size={18} />} title={`Website`} right={<></>} />
                <NewInput
                    onChange={e => setUpdateBrandChannelState('website', e)}
                    placeholder="https://example.com"
                    margin="0 15px"
                    width="93%"
                    fontSize="15px"
                    value={updataBrandChannelState.website}
                    disabled={!isAdmin}
                />
                <MenuItem icon={<FaHashtag size={18} />} title={`Default Hashtags`} right={<></>} />
                <NewInput
                    onChange={e => handleHashtags(e)}
                    placeholder="#default #hashtag"
                    margin="0 15px"
                    width="93%"
                    fontSize="15px"
                    value={hashtags}
                    disabled={!isAdmin}
                />
            </div>
            {isAdmin ? (
                <div className={styles.footer}>
                    <ButtonComponent
                        width="100%"
                        margin="10px 0 0 0"
                        text="Save"
                        clickMe={handleSave}
                        isLoading={isLoading}
                    />
                </div>
            ) : null}
        </>
    );
};

export default observer(BrandGroupChat);
