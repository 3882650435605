import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Header/Header';
import useRootStore from '../../../hooks/useRootStore';
import styles from './GroupPolicyManager.module.css';
import Text from '../../../components/Text/Text';
import { FiUpload } from 'react-icons/fi';
import { ButtonComponent } from '../../../utils/button';
import { DownloadIcon } from '../../../utils/icons';
import { message } from 'antd';
import ToolbarIcon from '../../../components/ToolbarIcon/ToolbarIcon';
import { saveAs } from 'file-saver';
import { TMP_URL } from '../../../env';
import { useEffect } from 'react';

const GroupPolicyManager = () => {
    const { t } = useTranslation();
    const { closeModal } = useRootStore().routerStore;
    const { user } = useRootStore().authStore;
    const { updatePrivacyPolicy, updataBrandChannelState, setUpdateBrandChannelState, isLoading } =
        useRootStore().channelProStore;
    const { channelData, readFile, hashId } = useRootStore().channelStore;
    const { getChannelByHashIdForPrivacyPolicy } = useRootStore().channelProStore;

    useEffect(() => {
        getChannelByHashIdForPrivacyPolicy(hashId);
    }, []);

    const isAdmin = channelData.adminId === user.id;

    const uploadFile = (e: File, key: string) => {
        readFile(e, key, 'edit');
    };

    const handleBack = () => {
        setUpdateBrandChannelState('privateGroupPolicyText', channelData.privateGroupPolicyText);
        setUpdateBrandChannelState('privateGroupPolicyFileLink', channelData.privateGroupPolicyFileLink);
        closeModal('right');
    };

    const handleSave = () => {
        updatePrivacyPolicy(
            hashId,
            updataBrandChannelState?.privateGroupPolicyText as never,
            updataBrandChannelState.privateGroupPolicyFileLink as never,
            () => closeModal('right'),
        );
    };

    const downloadFile = () => {
        saveAs(`${TMP_URL}/${updataBrandChannelState.privateGroupPolicyFileLink}`, 'privacy policy');
    };

    return (
        <div>
            <Header
                text={t('group_policy')}
                isPremium={channelData.isPremium}
                color={channelData.mainColor}
                colorPText={channelData.colorHighlight}
                leftIcon="arrowRight"
                onLeftIconPress={handleBack}
                textWeight={600}
            />
            <div className={styles.header}>
                {isAdmin && (
                    <div className={styles.upload}>
                        <button className={styles.uploadLabel}>
                            <Text fontSize="18" style={{ marginBottom: '10px' }}>
                                Upload {updataBrandChannelState?.privateGroupPolicyFileLink && 'new'} group policy{' '}
                                <br />
                            </Text>
                            <span className={styles.fileUrl}>
                                <ToolbarIcon
                                    accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .csv, .jpg, .jpeg, .png, .gif, .bmp"
                                    onChange={e => uploadFile(e, 'privateGroupPolicyFileLink')}
                                >
                                    <FiUpload size={20} />
                                </ToolbarIcon>
                            </span>
                        </button>
                    </div>
                )}
                {updataBrandChannelState?.privateGroupPolicyFileLink && (
                    <div className={styles.upload}>
                        <button className={styles.uploadLabel} onClick={downloadFile}>
                            <Text fontSize="18" style={{ marginBottom: '10px' }}>
                                Download group policy
                            </Text>
                            <DownloadIcon size={20} />
                        </button>
                    </div>
                )}
                <div className={styles.descriptionBox}>
                    <Text fontSize="18" style={{ marginTop: '30px', marginBottom: '10px' }}>
                        Private Group Policy
                    </Text>
                    <textarea
                        disabled={!isAdmin}
                        className={styles.description}
                        rows={22}
                        placeholder="Group Privacy Policy"
                        value={updataBrandChannelState.privateGroupPolicyText}
                        onChange={e => setUpdateBrandChannelState('privateGroupPolicyText', e.target.value)}
                    />
                </div>
            </div>
            {isAdmin && (
                <div className={styles.footer}>
                    <ButtonComponent
                        isLoading={isLoading}
                        disabled={
                            !updataBrandChannelState.privateGroupPolicyText?.length &&
                            !updataBrandChannelState.privateGroupPolicyFileLink?.length
                        }
                        width="100%"
                        margin="10px 0 0 0"
                        text="Save file/text"
                        clickMe={handleSave}
                    />
                </div>
            )}
        </div>
    );
};

export default observer(GroupPolicyManager);
