import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import MessageComponent from '../../../components/Chat/MessageComponent/MessageComponent';
import ModalToPrivateChannel from '../../../components/ModalToPrivateChannel';
import ScrollContainer from '../../../components/ScrollContainer/ScrollContainer';
import useRootStore from '../../../hooks/useRootStore';
import ChatHeader from '../../../utils/chatHeader';
import MessageInput from './components/messageInput/MessageInput';
import CustomSkeleton from '../../../components/MessageSkeleton/MessageSkeleton';
import { SkeletonData } from '../../../utils/skeleton';

const Chat = () => {
    console.log('Bu prod muhitida!', process.env.NODE_ENV);

    const navigate = useNavigate();
    const params = useParams();
    const { messagesFilterValue, setIsMessagesLength, getSelectedChannelMsgs, searchMessages } =
        useRootStore().messageStore;
    const { closeRightSideBar } = useRootStore().routerStore;
    const { myChannels, channelUsers, clearSelectChannel, getChannelByHashId, handelSelectedChannel, isLoad } =
        useRootStore().channelStore;
    const { show, setImageFile } = useRootStore().visibleStore;
    const { user } = useRootStore().authStore;
    const { slug, searchMessageId, setChannelSlug, exitChannelEmit, setSendMessage, isLoadMessages } =
        useRootStore().messageStore;

    useEffect(() => {
        const handleEsc = (event: any) => {
            if (event.keyCode === 27) {
                exitChannelEmit();
                navigate('/', { replace: true });
                closeRightSideBar();
                setChannelSlug('');
                clearSelectChannel();
            }
        };

        window.addEventListener('keydown', handleEsc);

        const handleHome = event => {
            event.preventDefault();
            navigate('/', { replace: true });
            exitChannelEmit();
            setChannelSlug('');
            clearSelectChannel();
        };

        window.addEventListener('popstate', handleHome);

        return () => {
            window.removeEventListener('keydown', handleEsc);
            window.addEventListener('popstate', handleHome);
        };
    }, [closeRightSideBar, navigate]);

    useEffect(() => {
        if (params?.name) {
            let hashId = params.name.replace('@', '');
            getChannelByHashId(hashId);
            let channel = myChannels.find(channel => channel.hashId === hashId);
            if (channel) {
                handelSelectedChannel({ id: channel.id, hashId: hashId, slug: channel.slug });
                setChannelSlug(channel.slug);
            }
        }
    }, [params?.name]);

    useEffect(() => {
        if (searchMessageId) {
            const element = document.getElementById(searchMessageId);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }
    }, [searchMessageId]);

    const messagesV2: React.ReactNode[] = useMemo(() => {
        if (isLoad) {
            // Render Ant Design Skeletons if messages are loading
            return SkeletonData.map((_, index) => (
                <CustomSkeleton
                    key={index}
                    width={_.width}
                    position={_.position}
                    marginBottom={index === SkeletonData.length - 1 ? 80 : 0}
                />
            ));
        }

        const messagesData = getSelectedChannelMsgs;

        if (!messagesData || messagesData.length === 0) {
            setIsMessagesLength(true);
            return [];
        }

        const messagesForSee = messagesData.filter(
            item =>
                item.mentionedUserIds.length === 0 ||
                String(item.mentionedUserIds[0]) === '0' ||
                item.userId === user.id ||
                item.mentionedUserIds.includes(`${user.id}` as never) ||
                item.mentionedUserIds.includes(user.id),
        );

        setIsMessagesLength(false);

        const filteredMessages = messagesForSee.filter(
            msg => msg.relevance !== undefined && msg.relevance >= messagesFilterValue,
        );

        const messageComponents: React.ReactNode[] = [];

        for (let i = 0; i < filteredMessages.length; i++) {
            const msg = filteredMessages[i];

            messageComponents.unshift(
                <MessageComponent
                    key={msg.id}
                    isFirst={msg === filteredMessages[0]}
                    isLast={filteredMessages.length - 1 === i}
                    _ref={ref => {}}
                    message={msg}
                    users={channelUsers}
                    isSearchMessage={
                        (!!searchMessages?.count &&
                            !!searchMessages.messages.find(SMessage => SMessage.id === msg.id)?.id) ??
                        false
                    }
                />,
            );
        }

        return messageComponents;
    }, [
        getSelectedChannelMsgs,
        messagesFilterValue,
        channelUsers,
        setIsMessagesLength,
        searchMessages.count,
        params?.name,
        isLoad,
    ]);

    const handlePaste = event => {
        event.preventDefault();

        const items = event.clipboardData.items;

        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const blob = items[i].getAsFile();
                if (blob) {
                    const img = new Image();
                    const imageUrl = URL.createObjectURL(blob);

                    img.src = imageUrl;

                    img.onload = () => {
                        const width = img.width;
                        const height = img.height;

                        setImageFile({
                            blob,
                            width,
                            height,
                        });
                        show('ImagePrewModal');
                        show('isPaste');
                    };
                }
            }
        }
    };

    return (
        <ChatContainer id="chatView" onPaste={handlePaste}>
            <ChatHeader />
            <ScrollContainer>{messagesV2}</ScrollContainer>
            <MessageInput />
            <ModalToPrivateChannel />
        </ChatContainer>
    );
};

export default observer(Chat);

const ChatContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
`;
