import React, { useState } from 'react';
import { SearchIcon } from '../../assets/icons/icons';
import styles from './SearchInput.module.css';
import { CloseIcon } from '../../utils/icons';
interface Props {
    placeholder?: string;
    onChange: (e: any) => void;
    value?: any;
    onClick?: () => void;
}

const SearchInput: React.FC<Props> = ({ placeholder, value, onChange, onClick }) => {
    const [text, setText] = useState('');

    const handleClear = () => {
        setText('');
        onChange('');
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        onChange(newValue);
        setText(newValue);
    };

    return (
        <div className={styles.searchBox}>
            <div className={styles.iconBox}>
                <SearchIcon />
            </div>
            <input
                className={styles.input}
                placeholder={placeholder}
                type="text"
                value={text}
                onChange={handleChange}
                onClick={onClick}
            />
            {!!text.length && (
                <div onClick={handleClear} className={styles.iconBox}>
                    <CloseIcon size={12} color="#8f8f8f" padding={5} />
                </div>
            )}
        </div>
    );
};

export default SearchInput;
