import { Popconfirm } from 'antd';
import { CSSProperties, FC, useMemo } from 'react';
import {
    AccountIcon,
    ArrowLeftIcon,
    ArrowRightIcon,
    CloseIcon,
    GlobalSearchIcon,
    LogoutIcon,
    MdGroupAddIcon,
    MoreDotIcon,
    SettingIcon,
} from '../../utils/icons';
import styles from './index.module.css';
import OutlineButton from '../OutlineButton/OutlineButton';
import { FaStar } from 'react-icons/fa';

interface Props {
    header?: string;
    text?: any;
    rightIcon?: string;
    onRightIconPress?: () => void;
    leftIcon?: string;
    onLeftIconPress?: () => void;
    colorText?: string;
    rightIconColor?: string;
    leftIconColor?: string;
    textSize?: string;
    style?: CSSProperties | any;
    popConfirm?: () => void;
    popCancel?: () => void;
    popTitle?: string;
    popQuest?: string;
    color?: string;
    colorPText?: string;
    textWeight?: number;
    isPremium?: boolean;
}
const Header: FC<Props> = ({
    header,
    text,
    rightIcon,
    leftIcon,
    rightIconColor = '#444',
    leftIconColor = '#444',
    colorText = ' #404d66',
    textSize = '18',
    onRightIconPress = () => {},
    onLeftIconPress = () => {},
    style,
    popConfirm,
    popCancel,
    popQuest,
    popTitle,
    textWeight = '600',
    isPremium,
    color,
    colorPText,
}) => {
    const LeftIconButton = useMemo(() => {
        switch (leftIcon) {
            case 'account':
                return AccountIcon;
            case 'arrowLeft':
                return ArrowLeftIcon;
            case 'arrowRight':
                return ArrowRightIcon;
            case 'setting':
                return SettingIcon;
            case 'logout':
                return LogoutIcon;
            case 'addUser':
                return MdGroupAddIcon;
            case 'search':
                return GlobalSearchIcon;
            case 'close':
                return CloseIcon;
            default:
                return null;
        }
    }, [leftIcon]);

    const RightIconButton = useMemo(() => {
        switch (rightIcon) {
            case 'more':
                return MoreDotIcon;
            case 'arrowLeft':
                return ArrowLeftIcon;
            case 'arrowRight':
                return ArrowRightIcon;
            case 'setting':
                return SettingIcon;
            case 'logout':
                return LogoutIcon;
            case 'addUser':
                return MdGroupAddIcon;
            case 'close':
                return CloseIcon;
            default:
                return null;
        }
    }, [rightIcon]);

    return (
        <div
            className={styles.container}
            style={{ ...style, backgroundColor: color ? color : 'rgba(255, 255, 255, 0.404)' }}
        >
            <div>
                {LeftIconButton && (
                    <OutlineButton onClick={onLeftIconPress}>
                        <LeftIconButton color={leftIconColor} />
                    </OutlineButton>
                )}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '5px', justifySelf: 'center' }}>
                <div
                    style={{
                        fontWeight: `${textWeight}`,
                        fontSize: `${textSize}px`,
                        color: isPremium ? `${colorPText}` : `${colorText}`,
                    }}
                >
                    {text}
                </div>
                {isPremium ? <FaStar size={20} color="#ffdd59" /> : null}
            </div>
            <div>
                {RightIconButton && (
                    <OutlineButton onClick={onRightIconPress}>
                        <RightIconButton color={rightIconColor} />
                    </OutlineButton>
                )}
                {rightIcon === 'logout' ? (
                    <Popconfirm
                        title={popTitle}
                        description={popQuest}
                        onConfirm={popConfirm}
                        onCancel={popCancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div onClick={onRightIconPress}>
                            <LogoutIcon color={rightIconColor} />
                        </div>
                    </Popconfirm>
                ) : null}
            </div>
        </div>
    );
};

export default Header;
