import { DataObject } from '../types';
import { Hashtag } from '../types/channel';

export const hashtagExists = (text: string, hashtags: Hashtag[]) => {
    if (text !== '') {
        for (let i = 0; i < hashtags.length; i++) {
            if (hashtags[i].text === text) {
                return true;
            }
        }
    }
    return false;
};

export function extractHourMinute(dateTimeString: Date) {
    // Convert the provided date string to a Date object
    const dateTime = new Date(dateTimeString);

    // Get the hour and minute components from the Date object
    // const hour = dateTime.getUTCHours();
    // const minute = dateTime.getUTCMinutes();
    const hour = String(dateTime.getHours()).padStart(2, '0');
    const minute = String(dateTime.getMinutes()).padStart(2, '0');

    return `${hour}:${minute}`;
}

export const parseJsonData = (jsonData: string): DataObject => {
    try {
        return JSON.parse(jsonData);
    } catch (error) {
        console.error('JSON parsing error:', error);
        return {};
    }
};

export const secondsToMS = (seconds: number) => {
    if (seconds > 0) {
        const minutes: number = Math.floor((seconds % 3600) / 60);
        const remainingSeconds: number = seconds % 60;
        return `${minutes < 10 ? `0${minutes}` : minutes}:${
            remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
        }`;
    } else {
        return '00:00';
    }
};

export function debounce<T extends (...args: any[]) => any>(func: T, delay: number): (...args: Parameters<T>) => void {
    let timeout: NodeJS.Timeout;
    return (...args: Parameters<T>): void => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), delay);
    };
}
