import { Input, message, Slider, SliderSingleProps, Switch } from 'antd';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoMdFunnel, IoMdSettings } from 'react-icons/io';
import { GoPasskeyFill } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import AvatarUpload from '../../../components/AvatarUpload/AvatarUpload';
import Header from '../../../components/Header/Header';
import MenuItem from '../../../components/MenuItem/MenuItem';
import NewInput from '../../../components/NewInput/NewInput';
import { TMP_URL } from '../../../env';
import useRootStore from '../../../hooks/useRootStore';
import { ButtonComponent } from '../../../utils/button';
import { findKeyByValue } from '../../../utils/Common';
import styles from './EditChannel.module.css';
import { MdGroup, MdWorkspacePremium } from 'react-icons/md';
import { RiChatPrivateLine, RiFileCopyFill } from 'react-icons/ri';
import { PiSlidersHorizontalBold } from 'react-icons/pi';
import { toJS } from 'mobx';

const STEPS = { 1: '1 km', 25: '10 km', 50: '100 km', 75: '1000 km', 100: 'World Wide' };

const EditChannel = () => {
    const { t } = useTranslation();
    const { closeModal, toRouterManageCh, closeRightSideBar } = useRootStore().routerStore;
    const { getFriends } = useRootStore().friendsStore;
    const navigation = useNavigate();
    const { show, toglevisible, visible } = useRootStore().visibleStore;
    const [location, setLocation] = useState({ latitude: null, longitude: null });

    const {
        channelData,
        setUpdateChannelState,
        updateChannel,
        delateChannel,
        setUpdataChannel,
        channelAvatar,
        channelAvatarLoading,
        setCropAvatarState,
        updateLoading,
    } = useRootStore().channelStore;

    const { updateBrandGroup, updataBrandChannelState, setUpdateBrandChannelState } = useRootStore().channelProStore;

    const updateChannelEvent = () => {
        updateChannel(setUpdataChannel, () => closeModal('right'));
        updateBrandGroup(updataBrandChannelState);
    };

    const onChangeGroupType = (checked: boolean) => {
        setUpdateChannelState('isPrivate', checked);
    };
    const onChangeGroupTypeNormal = (checked: boolean) => {
        setUpdateBrandChannelState('isPremium', checked);
    };

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.length) {
            setCropAvatarState(e.target.files[0], 'updataChannel');
            show('chUploadFile');
        }
    };

    const deleteEvent = () => {
        navigation('', { replace: true });
        closeRightSideBar();
    };

    const delateChannelEvent = (hashId: string) => {
        delateChannel(hashId, () => deleteEvent());
    };

    const handleChangeLocation = (num: number) => {
        setUpdateChannelState('rByLocationType', STEPS[num]);
    };

    const addUserToChannel = () => {
        toRouterManageCh('addUserToChannelEdit');
        getFriends();
    };

    const handleAskLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    setLocation({
                        latitude: position.coords.latitude as any,
                        longitude: position.coords.longitude as any,
                    });
                },
                err => {
                    message.error(err.message);
                },
            );
            toglevisible('relevanceByLocation');
            if (!!setUpdataChannel.rByLocationType?.length) {
                setUpdateChannelState('rByLocationType', '');
            } else {
                setUpdateChannelState('rByLocationType', '1 km');
            }
        } else {
            message.error('Geolocation is not supported by this browser.');
        }
    };

    const copyChatLink = () => {
        navigator.clipboard.writeText(window.location.href);
        message.success('Chat invite link copied');
    };

    const marks: SliderSingleProps['marks'] = STEPS;

    return (
        <div className={styles.editChannel}>
            <Header
                leftIcon="arrowLeft"
                text={t('editGroup')}
                onLeftIconPress={() => closeModal('right')}
                isPremium={channelData.isPremium}
                color={channelData.mainColor}
                colorPText={channelData.colorHighlight}
            />
            <div className={styles.content}>
                <div className={styles.avatarBox}>
                    <AvatarUpload
                        imageUrl={
                            channelAvatar
                                ? channelAvatar
                                : setUpdataChannel.avatar
                                  ? `${TMP_URL}/${setUpdataChannel.avatar}`
                                  : ''
                        }
                        loading={channelAvatarLoading}
                        color={
                            channelData.color
                                ? `linear-gradient(25deg, ${channelData.color} 30%, #ddd 100%)`
                                : 'linear-gradient(#ddd, #666)'
                        }
                        upload={true}
                        onChange={e => handleFileChange(e)}
                    />
                </div>
                <NewInput
                    onChange={e => setUpdateChannelState('name', e)}
                    placeholder="Group Name"
                    margin="15px 0"
                    fontSize="15px"
                    value={setUpdataChannel.name}
                />
                <div className={styles.descriptionBox}>
                    <textarea
                        className={styles.description}
                        rows={6}
                        placeholder="Enter description"
                        value={setUpdataChannel.description ?? channelData.description}
                        onChange={e => setUpdateChannelState('description', e.target.value)}
                    />
                </div>
                <MenuItem
                    icon={<RiChatPrivateLine size={24} />}
                    title={`Group type: ${setUpdataChannel.isPrivate ? 'Private' : 'Public'}`}
                    right={<Switch onChange={onChangeGroupType} checked={!!setUpdataChannel.isPrivate} />}
                />
                {setUpdataChannel.isPrivate && (
                    <Input
                        placeholder="Enter password"
                        type="number"
                        value={setUpdataChannel.password}
                        onChange={e => setUpdateChannelState('password', e.target.value)}
                    />
                )}
                <MenuItem
                    icon={<MdWorkspacePremium size={24} />}
                    title={`Group type: ${updataBrandChannelState.isPremium ? 'Premium' : 'Normal'}`}
                    right={<Switch onChange={onChangeGroupTypeNormal} checked={!!updataBrandChannelState.isPremium} />}
                />

                <MenuItem
                    icon={<IoMdFunnel size={24} />}
                    title="Relevance by location"
                    right={
                        <Switch
                            onChange={() => handleAskLocation()}
                            checked={visible.relevanceByLocation || !!setUpdataChannel.rByLocationType?.length}
                        />
                    }
                />
                <div
                    className={styles.sliderBox}
                    style={{
                        display:
                            visible.relevanceByLocation || !!setUpdataChannel.rByLocationType?.length
                                ? 'block'
                                : 'none',
                    }}
                >
                    <Slider
                        step={null}
                        min={1}
                        max={100}
                        marks={marks}
                        defaultValue={findKeyByValue(setUpdataChannel.rByLocationType as string, marks)}
                        // value={25}
                        className={styles.slider}
                        onChange={e => handleChangeLocation(e)}
                    />
                </div>
                <MenuItem
                    icon={<IoMdFunnel size={24} />}
                    title="Default relevance"
                    onClick={() => toglevisible('relevenceSliderEdit')}
                    right={setUpdataChannel.defaultRelevance ? setUpdataChannel.defaultRelevance : '0'}
                />
                <div
                    className={styles.sliderBox}
                    style={{
                        display: visible.relevenceSliderEdit ? 'block' : 'none',
                    }}
                >
                    <Slider
                        defaultValue={setUpdataChannel.defaultRelevance}
                        className={styles.slider}
                        onChange={e => setUpdateChannelState('defaultRelevance', e)}
                    />
                </div>
                {channelData.isPremium && (
                    <>
                        <MenuItem
                            icon={<PiSlidersHorizontalBold size={24} />}
                            title="Minimum relevance to text"
                            onClick={() => toglevisible('minRelevanceOfText')}
                            right={
                                updataBrandChannelState.minRelevanceOfText
                                    ? updataBrandChannelState.minRelevanceOfText
                                    : '0'
                            }
                        />
                        <div
                            className={styles.sliderBox}
                            style={{
                                display: visible.minRelevanceOfText ? 'block' : 'none',
                            }}
                        >
                            <Slider
                                defaultValue={updataBrandChannelState.minRelevanceOfText}
                                className={styles.slider}
                                onChange={e => setUpdateBrandChannelState('minRelevanceOfText', e)}
                            />
                        </div>
                        <MenuItem
                            icon={<PiSlidersHorizontalBold size={24} />}
                            title="Increase quality of image"
                            onClick={() => toglevisible('qualityOfImage')}
                            right={
                                updataBrandChannelState.qualityOfImage ? updataBrandChannelState.qualityOfImage : '0'
                            }
                        />
                        <div
                            className={styles.sliderBox}
                            style={{
                                display: visible.qualityOfImage ? 'block' : 'none',
                            }}
                        >
                            <Slider
                                defaultValue={updataBrandChannelState.qualityOfImage}
                                className={styles.slider}
                                onChange={e => setUpdateBrandChannelState('qualityOfImage', e)}
                            />
                        </div>
                    </>
                )}
                <MenuItem icon={<MdGroup size={24} />} title="Add participiants" onClick={addUserToChannel} />
                <MenuItem
                    icon={<RiFileCopyFill size={24} />}
                    title="Share chat invite link"
                    onClick={copyChatLink}
                    right="  "
                />
                <div className={styles.btnSave}>
                    <ButtonComponent
                        backColor="transparent"
                        text={`${t('deleteGroup')}`}
                        color="red"
                        clickMe={() => delateChannelEvent(channelData.hashId)}
                    />
                    <ButtonComponent
                        loadingType="bounce"
                        isLoading={updateLoading}
                        text="Save"
                        width="100%"
                        clickMe={updateChannelEvent}
                    />
                </div>
            </div>
        </div>
    );
};

export default observer(EditChannel);
