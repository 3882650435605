import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { IoIosSettings, IoMdNotifications } from 'react-icons/io';
import { MdGroup, MdLogout } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import MenuItem from '../../../components/MenuItem/MenuItem';
import useRootStore from '../../../hooks/useRootStore';
import { ButtonComponent } from '../../../utils/button';
import Colors from '../../../utils/colors';
import styles from './AccountSetting.module.css';
import { Button, Popconfirm } from 'antd';
import Text from '../../../components/Text/Text';

const AccountSetting = () => {
    const { t } = useTranslation();
    const { logout, user, deleteUser } = useRootStore().authStore;
    const { closeModal, toRouter } = useRootStore().routerStore;
    const navigation = useNavigate();

    const onLogout = () => {
        logout(() => navigation('/auth/welcome'));
    };

    const onFriends = () => {
        toRouter('friends');
    };

    const onPreferences = () => {
        toRouter('preferences');
    };

    return (
        <div className={styles.container}>
            <Header text={t('settings')} leftIcon="arrowLeft" onLeftIconPress={() => closeModal('left')} />
            <div className={styles.content}>
                <MenuItem title="Notifications" icon={<IoMdNotifications size={22} />} />
                <a href="https://chatxo.app/impressum" target={'_blank'} rel="noreferrer">
                    <MenuItem title="Terms and Conditions" icon={<IoIosSettings size={22} />} />
                </a>
                <a href="https://chatxo.app/privacy-policy" target={'_blank'} rel="noreferrer">
                    <MenuItem title="Privacy Statement" icon={<IoIosSettings size={22} />} />
                </a>
                <MenuItem title="Preferences" icon={<IoIosSettings size={22} />} onClick={onPreferences} />
            </div>
            <div className={styles.bottomBox}>
                <Popconfirm
                    title=""
                    description="Are you sure you want to sign out from your account?"
                    // onConfirm={}
                    onCancel={onLogout}
                    okText="Keep"
                    cancelText="Sign Out"
                >
                    <Button danger style={{ border: 'none', backgroundColor: 'transparent', boxShadow: 'none' }}>
                        <Text children={'Sign Out'} fontSize="14px" textAlign={'center'} />
                    </Button>
                </Popconfirm>
            </div>
        </div>
    );
};

export default observer(AccountSetting);
