import { makeAutoObservable } from 'mobx';
import { TypeImageFile } from '../../types/messageType';

class VisibleStore {
    constructor() {
        makeAutoObservable(this);
    }

    imageFile?: TypeImageFile = {} as TypeImageFile;

    visible = {
        FooterToolbar: false,
        FilterToolbar: false,
        RelevenceModal: false,
        previewModal: false,
        chUploadFile: false,
        showPass: false,
        passwordInput: false,
        setSearch: false,
        pollMesssageCard: false,
        openFooterMediaBar: false,
        rightSidebar: false,
        menuChannel: false,
        relevenceSliderCreate: false,
        relevenceSliderEdit: false,
        newChannelToJoinModal: false,
        qualityOfImage: false,
        minRelevanceOfText: false,
        relevanceByLocation: false,
        ImagePrewModal: false,
        openHashtag: false,
        filterMinRelevance: false,
        isOpenAllHashtags: false,
        isPaste: false,
        privacyPolicy: false,
        isHasGroup: false,
    };

    toglevisible = (key: keyof typeof this.visible) => {
        this.visible[key] = !this.visible[key];
    };

    show = (key: keyof typeof this.visible) => {
        this.visible[key] = true;
    };

    hide = (key: keyof typeof this.visible) => {
        this.visible[key] = false;
    };

    setImageFile = (data: TypeImageFile) => {
        this.imageFile = data;
    };
}

export default VisibleStore;
