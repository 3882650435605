import { message } from 'antd';
import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FaStar } from 'react-icons/fa';
import { MdLocationPin } from 'react-icons/md';
import { generatePath, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import ChannelRowItem from '../../../components/ChanneItem/ChannelItem';
import Header from '../../../components/Header/Header';
import { Loading } from '../../../components/Loading/Loading';
import MessageBox from '../../../components/MessageBox/MessageBox';
import SearchInput from '../../../components/SearchInput/SearchInput';
import Text from '../../../components/Text/Text';
import { TMP_URL } from '../../../env';
import useRootStore from '../../../hooks/useRootStore';
import { Channel } from '../../../types/channel';
import Colors from '../../../utils/colors';
import styles from './index.module.css';
import { toJS } from 'mobx';

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.2,
        },
    },
};

const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
    },
};

const ChannelsScreen = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { closeChannelInUser, toRouter } = useRootStore().routerStore;
    const { myChannels, setSearchChannels, getChannelByHashId, handelSelectedChannel, selectedChannelData } =
        useRootStore().channelStore;
    const { setChannelSlug, unreadMessages, setUnreadMessages, exitChannelEmit } = useRootStore().messageStore;
    const { openChannel } = useRootStore().chatStore;
    const { show, hide } = useRootStore().visibleStore;
    const { setUserState } = useRootStore().usersStore;
    const { user } = useRootStore().authStore;
    const { clearHashTags } = useRootStore().hashtagStore;
    const { getChannelByHashIdForPrivacyPolicy } = useRootStore().channelProStore;

    const serachChannelHandler = (text: string) => {
        setSearchChannels(text);
    };

    // useEffect(() => {
    //     const handleEsc = (event: any) => {
    //         if (event.keyCode === 27) {
    //             exitChannelEmit();
    //             setChannelSlug('');
    //             navigate('/', { replace: true });
    //         }
    //     };

    //     window.addEventListener('keydown', handleEsc);

    //     const handleHome = event => {
    //         event.preventDefault();
    //         navigate('/home', { replace: true });
    //         console.log('home');
    //         exitChannelEmit();
    //         setChannelSlug('');
    //         console.log('popstate');
    //     };

    //     window.addEventListener('popstate', handleHome);
    //     // window.addEventListener('popstate', () => {
    //     //     console.log('popstate');
    //     // });

    //     return () => {
    //         window.removeEventListener('keydown', handleEsc);
    //         window.removeEventListener('popstate', handleHome);
    //     };
    // }, [navigate]);

    const handleSelectChanel = useCallback(
        (e: Channel) => {
            if (
                e.users?.find(item => item.id === user.id)?.acceptedPolicyVersion === e?.policyVersion ||
                e?.policyVersion === 0
            ) {
                hide('openHashtag');
                hide('FilterToolbar');
                hide('filterMinRelevance');
                hide('openFooterMediaBar');
                hide('isOpenAllHashtags');
                clearHashTags();
                if (unreadMessages.length && unreadMessages.some(item => item.slug == e.slug)) {
                    let newUnreadMessage = unreadMessages.map(item => {
                        if (item.slug == e.slug) {
                            return {
                                ...item,
                                count: 0,
                            };
                        } else {
                            return item;
                        }
                    });

                    setUnreadMessages(newUnreadMessage);
                }

                if (e?.rByLocationType && !!e.rByLocationType?.length) {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(
                            position => {
                                setUserState('latitude', position.coords.latitude as any);
                                setUserState('longitude', position.coords.longitude as any);
                                // updateUserAccount(setMyData);
                                handelSelectedChannel({ id: e.id, hashId: e.hashId, slug: e.slug });
                                // getChannelByHashId(e.hashId);
                                setChannelSlug(e.slug);
                                const target = generatePath(`/:name`, { name: `@${e.hashId}` });
                                navigate(target, { replace: true });
                                closeChannelInUser();
                                // openChannel(e.slug);
                            },
                            err => {
                                message.error(err.message);
                            },
                        );
                    } else {
                        message.error('Geolocation is not supported by this browser.');
                    }
                } else {
                    handelSelectedChannel({ id: e.id, hashId: e.hashId, slug: e.slug });
                    // getChannelByHashId(e.hashId);
                    setChannelSlug(e.slug);
                    const target = generatePath(`/:name`, { name: `@${e.hashId}` });
                    navigate(target, { replace: true });
                    closeChannelInUser();
                    // openChannel(e.slug);
                }
            } else {
                getChannelByHashIdForPrivacyPolicy(e.hashId);
                show('privacyPolicy');
                show('isHasGroup');
            }
        },
        [
            closeChannelInUser,
            getChannelByHashId,
            handelSelectedChannel,
            navigate,
            openChannel,
            setChannelSlug,
            unreadMessages,
            user,
        ],
    );

    const openMenu = () => {
        show('menuChannel');
        hide('openHashtag');
        hide('FilterToolbar');
        hide('filterMinRelevance');
        hide('openFooterMediaBar');
        hide('isOpenAllHashtags');
    };

    const handleGlobalSearch = () => {
        toRouter('channelSearchGlobal');
    };

    const toolbarClose = () => {
        hide('openHashtag');
        hide('FilterToolbar');
        hide('filterMinRelevance');
        hide('openFooterMediaBar');
        hide('isOpenAllHashtags');
        clearHashTags();
    };

    return (
        <LeftAreaContainer>
            <Header
                text={t('groups')}
                rightIcon="more"
                leftIcon="search"
                onLeftIconPress={handleGlobalSearch}
                onRightIconPress={openMenu}
            />
            <div className={styles.SearchBox}>
                <SearchInput
                    onClick={toolbarClose}
                    onChange={serachChannelHandler}
                    placeholder={`${t('searchPlaceholder')}`}
                />
            </div>
            <ChannelRowContainer>
                {false && (
                    <div className={styles.loadingBox}>
                        <Loading />
                    </div>
                )}
                {false && (
                    <div className={styles.loadingError}>
                        <MessageBox title={t('No Internet Connection')} />
                    </div>
                )}
                {myChannels.length === 0 && (
                    <div className={styles.loadingError}>
                        <MessageBox title={t('No Channels')} />
                    </div>
                )}
                <motion.div variants={container} initial="hidden" animate="visible" className={styles.contentBox}>
                    {myChannels.map(e => {
                        return (
                            <motion.div
                                variants={item}
                                whileTap={{ scale: 0.8 }}
                                key={e.id}
                                id="map-dev"
                                className={styles.channelRowBox}
                                onClick={() => handleSelectChanel(e)}
                            >
                                <ChannelRowItem
                                    item={e}
                                    name={e.name}
                                    color={
                                        e.color
                                            ? `linear-gradient(25deg, ${e.color.startsWith('#') ? e.color : '#' + e.color} 30%, #ddd 100%)`
                                            : ''
                                    }
                                    number={e.id}
                                    imageUrl={e.avatar ? `${TMP_URL}/${e.avatar}` : ''}
                                />
                                <div className={styles.channelNameBox}>
                                    <div>
                                        <Text
                                            children={e.name}
                                            color={Colors.White}
                                            fontSize="14px"
                                            margin="0px 0 0 0 "
                                        />
                                        {!!e.rByLocationType?.length && (
                                            <span className={styles.channelLocation}>
                                                <MdLocationPin color={Colors.DarkGray} size={11} />
                                                <Text
                                                    children={'by location'}
                                                    color={Colors.DarkGray}
                                                    fontSize="11px"
                                                    margin="0px 0 0 0 "
                                                />
                                            </span>
                                        )}
                                    </div>
                                    <Text
                                        children={`${e.users?.length} members`}
                                        color={Colors.White}
                                        fontSize="12px"
                                    />
                                </div>
                                <div className={styles.premiumIcon}>
                                    {e.isPremium ? <FaStar size={20} color="#ffdd59" /> : null}
                                </div>
                                {!!unreadMessages?.find(item => item.slug == e.slug)?.count && (
                                    <div className={styles.notifIcon}>
                                        <Text
                                            children={unreadMessages?.find(item => item.slug == e.slug)?.count ?? 0}
                                            color={Colors.White}
                                            fontSize="12px"
                                            margin="0"
                                        />
                                    </div>
                                )}
                                {e.isNew ? (
                                    <div className={styles.newGroup}>
                                        <Text children={'New group'} color={Colors.Black} fontSize="12px" margin="0" />
                                    </div>
                                ) : null}
                            </motion.div>
                        );
                    })}
                </motion.div>
            </ChannelRowContainer>
        </LeftAreaContainer>
    );
};

export default observer(ChannelsScreen);

const LeftAreaContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

const ChannelRowContainer = styled.div`
    position: relative;
    flex: 1;
    overflow-y: auto;
    padding-bottom: 20px;
`;
