import React, { FC } from 'react';
import { Skeleton } from 'antd';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

interface Props {
    width: number;
    position: string;
    marginBottom?: number;
}

const CustomSkeleton: FC<Props> = ({ width, position, marginBottom }) => {
    return (
        <SkeletonWrapper
            style={{ justifySelf: position === 'left' ? 'flex-start' : 'flex-end', marginBottom: marginBottom }}
        >
            {position === 'left' && (
                <Skeleton.Avatar
                    active
                    size="large"
                    shape="circle"
                    style={{ marginRight: '10px', marginBottom: '8px' }}
                />
            )}
            <SkeletonContent>
                <Skeleton.Input active size="small" style={{ width: width, marginBottom: '8px' }} />
                <Skeleton.Input active size="small" style={{ width: width, marginBottom: '8px', height: 50 }} />
            </SkeletonContent>
        </SkeletonWrapper>
    );
};

export default observer(CustomSkeleton);

const SkeletonWrapper = styled.div`
    display: flex;
    align-items: flex-end;
    border-radius: 10px;
    padding: 15px;
`;

const SkeletonContent = styled.div`
    display: flex;
    flex-direction: column;
    width: '100%';
    flex: 1;
`;
