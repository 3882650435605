import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Header/Header';
import useRootStore from '../../../hooks/useRootStore';
import styles from './GroupPolicy.module.css';
import Text from '../../../components/Text/Text';
import { FiUpload } from 'react-icons/fi';
import { ButtonComponent } from '../../../utils/button';
import { DownloadIcon } from '../../../utils/icons';
import { message } from 'antd';
import ToolbarIcon from '../../../components/ToolbarIcon/ToolbarIcon';

const GroupPolicy = () => {
    const { t } = useTranslation();
    const { closeModal } = useRootStore().routerStore;
    const { channelData, setCreateChannelState, setCreateChannelData, readFile } = useRootStore().channelStore;

    const handleFileUpload = e => {
        closeModal('left');
    };

    const uploadFile = (e: File, key: string) => {
        readFile(e, key);
    };

    const handleBack = () => {
        setCreateChannelState('privateGroupPolicyText', '');
        closeModal('left');
        message.info('Group Policy was not saved');
    };

    const handleSave = () => {
        message.info('Group Policy saved');
        closeModal('left');
    };

    return (
        <div>
            <Header
                text={t('group_policy')}
                colorPText={channelData.colorHighlight}
                leftIcon="arrowRight"
                onLeftIconPress={handleBack}
                textWeight={600}
            />
            <div className={styles.header}>
                <div className={styles.upload}>
                    <button className={styles.uploadLabel}>
                        <Text fontSize="18" style={{ marginBottom: '10px' }}>
                            Upload {setCreateChannelData?.privateGroupPolicyFileLink && 'new'} group policy <br />
                        </Text>
                        <span className={styles.fileUrl}>
                            <ToolbarIcon
                                accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .txt, .csv, .jpg, .jpeg, .png, .gif, .bmp"
                                onChange={e => uploadFile(e, 'privateGroupPolicyFileLink')}
                            >
                                <FiUpload size={20} />
                            </ToolbarIcon>
                        </span>
                    </button>
                </div>
                <div className={styles.descriptionBox}>
                    <Text fontSize="18" style={{ marginBottom: '10px' }}>
                        Private Group Policy
                    </Text>
                    <textarea
                        className={styles.description}
                        rows={20}
                        placeholder="Private Group Policy"
                        value={setCreateChannelData.privateGroupPolicyText}
                        onChange={e => setCreateChannelState('privateGroupPolicyText', e.target.value)}
                    />
                </div>
            </div>
            <div className={styles.footer}>
                <ButtonComponent
                    disabled={!setCreateChannelData.privateGroupPolicyText?.length}
                    width="100%"
                    margin="10px 0 0 0"
                    text="Save file/text"
                    clickMe={handleSave}
                />
            </div>
        </div>
    );
};

export default observer(GroupPolicy);
