import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import AvatarUpload from '../../../components/AvatarUpload/AvatarUpload';
import Header from '../../../components/Header/Header';
import MessageBox from '../../../components/MessageBox/MessageBox';
import RowItemView from '../../../components/RowItem';
import Text from '../../../components/Text/Text';
import { TMP_URL } from '../../../env';
import useRootStore from '../../../hooks/useRootStore';
import { ButtonComponent } from '../../../utils/button';
import Colors from '../../../utils/colors';
import styles from './channelInUser.module.css';
import { FaStar } from 'react-icons/fa';

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.2,
        },
    },
};

const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
    },
};

const ChannelInUser = () => {
    const { friendDetails, clearFriendDetails, blockUser, unblockUser, loading } = useRootStore().usersStore;
    const { friends, deleteFriend, createFriend } = useRootStore().friendsStore;
    const { closeModal } = useRootStore().routerStore;
    const { user } = useRootStore().authStore;
    const { t } = useTranslation();
    const { setChannelSlug } = useRootStore().messageStore;
    const { getChannelByHashId, myChannels, channelData } = useRootStore().channelStore;
    const navigate = useNavigate();
    const { show } = useRootStore().visibleStore;
    const { getPreviewData } = useRootStore().usersStore;

    const isFriend = useMemo(() => friends.some(e => e.id === friendDetails.id), [friends, friendDetails]);
    const isBlock = useMemo(
        () => user.blockedUsers?.includes(friendDetails.id.toString()),
        [user.blockedUsers, friendDetails],
    );

    const handleChanel = e => {
        setChannelSlug(e.slug);
        getChannelByHashId(myChannels.find(item => item?.id === e?.id)?.hashId as never);
        const target = generatePath(`/:name`, {
            name: `@${myChannels.find(item => item?.id === e?.id)?.hashId as never}`,
        });
        navigate(target);
    };

    const handeCreateOrDelete = () => {
        isFriend ? deleteFriend(friendDetails?.id as never) : createFriend(friendDetails?.id as never);
    };

    const PreviewAvatar = (data: any) => {
        show('previewModal');
        getPreviewData(data);
    };

    const cloesModal = () => {
        closeModal('right');
        clearFriendDetails();
    };

    return (
        <div className={styles.container}>
            <Header
                text={`${t('Profile')}`}
                leftIcon="arrowRight"
                colorText="white"
                onLeftIconPress={cloesModal}
                textWeight={600}
                color={channelData.mainColor}
                isPremium={channelData.isPremium}
                colorPText={channelData.colorHighlight}
            />
            <div className={styles.contentBox}>
                <AvatarUpload
                    imageUrl={friendDetails?.avatar ? `${TMP_URL}/${friendDetails.avatar}` : ''}
                    onPreview={() => PreviewAvatar(friendDetails)}
                    upload={false}
                    color={
                        friendDetails?.color
                            ? `linear-gradient(25deg, ${friendDetails.color.startsWith('#') ? friendDetails.color : '#' + friendDetails.color} 30%, #ddd 100%)`
                            : 'linear-gradient(#ddd, #666)'
                    }
                    onChange={() => {}}
                />
                <div className={styles.premiumUserName}>
                    <Text
                        children={friendDetails?.username ? friendDetails.username : 'User'}
                        margin="0 0 0 0"
                        fontWeight={700}
                        center
                        color={Colors.Black}
                    />
                    {/* {friendDetails.isPremium ? <FaStar size={20} color="#ffdd59" /> : null} */}
                </div>
            </div>
            <div className={styles.formBox}>
                <div className={styles.formItem}>
                    <Text
                        children="Username"
                        color={Colors.Black}
                        fontWeight={600}
                        fontSize="14px"
                        style={{ width: '50%' }}
                    />
                    <Text
                        children={friendDetails?.username}
                        color={Colors.Gray}
                        fontWeight={500}
                        fontSize="13px"
                        style={{ width: '50%' }}
                        moreDot
                    />
                </div>
                <div className={styles.formItem}>
                    <Text
                        children="Name"
                        color={Colors.Black}
                        fontWeight={600}
                        fontSize="14px"
                        style={{ width: '50%' }}
                    />
                    <Text
                        children={friendDetails?.name}
                        color={Colors.Gray}
                        fontWeight={600}
                        fontSize="13px"
                        style={{ width: '50%' }}
                        moreDot
                    />
                </div>
                <div className={styles.formItem}>
                    <Text
                        children="Email"
                        color={Colors.Black}
                        fontWeight={600}
                        fontSize="14px"
                        style={{ width: '50%' }}
                    />
                    <Text
                        children={friendDetails?.email}
                        color={Colors.Gray}
                        fontWeight={500}
                        fontSize="13px"
                        style={{ width: '50%' }}
                        moreDot
                    />
                </div>
                <div className={styles.formItem}>
                    <Text
                        children="City"
                        color={Colors.Black}
                        fontWeight={500}
                        fontSize="14px"
                        style={{ width: '50%' }}
                    />
                    <Text
                        children={friendDetails?.city}
                        color={Colors.Gray}
                        fontWeight={500}
                        fontSize="13px"
                        style={{ width: '50%' }}
                    />
                </div>
                <div className={styles.formItem}>
                    <Text
                        children="Age"
                        color={Colors.Black}
                        fontWeight={600}
                        fontSize="14px"
                        style={{ width: '50%' }}
                    />
                    <Text
                        children={friendDetails?.birth}
                        color={Colors.Gray}
                        fontWeight={500}
                        fontSize="13px"
                        style={{ width: '50%' }}
                    />
                </div>
                <div className={styles.formItem}>
                    <Text
                        children="Interests"
                        color={Colors.Black}
                        fontWeight={600}
                        fontSize="14px"
                        style={{ width: '50%' }}
                    />
                    <Text
                        children={friendDetails?.occupacy}
                        color={Colors.Gray}
                        fontWeight={500}
                        fontSize="13px"
                        style={{ width: '50%' }}
                    />
                </div>
            </div>
            <Text margin="15px 0 10px 7%" children={'Joint groups'} fontWeight={600} color={Colors.Black} />
            <motion.div variants={container} initial="hidden" animate="visible">
                {friendDetails?.channels?.length !== 0 ? (
                    friendDetails?.channels?.map((e, index) => {
                        return (
                            <motion.div variants={item} key={index} id="map-dev" className={styles.channelRowBox}>
                                <RowItemView
                                    color={e.color ? e.color : 'linear-gradient(#ddd, #666)'}
                                    imageUrl={e.avatar ? `${TMP_URL}/${e.avatar}` : ''}
                                    text={e.name}
                                    leng={25}
                                    loading={false}
                                    onPressComponent={() => handleChanel(e)}
                                    rightItem={e.isPremium && <FaStar size={20} color="#ffdd59" />}
                                />
                            </motion.div>
                        );
                    })
                ) : (
                    <MessageBox size="12px" title={`${t('no_avalible_groups')}`} />
                )}
            </motion.div>
            <ButtonComponent
                margin="20px 7% 15px 7%"
                width="86%"
                color={isBlock ? Colors.Red : Colors.Green}
                text={isBlock ? 'unblock user' : 'block user'}
                backColor="#fff"
                border="1px solid #7fa88b"
                clickMe={() => (isBlock ? unblockUser(friendDetails?.id) : blockUser(friendDetails?.id))}
                loadingType="dots"
                isLoading={loading}
            />
            <ButtonComponent
                margin="20px 7% 15px 7%"
                width="86%"
                color={isFriend ? Colors.Red : Colors.White}
                text={isFriend ? 'unfriend' : '+ add as friend'}
                clickMe={handeCreateOrDelete}
            />
        </div>
    );
};

export default observer(ChannelInUser);
