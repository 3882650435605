import React, { CSSProperties } from 'react';
import styles from './smallAvatar.module.css';
interface Props {
    imageUrl?: string;
    color?: string;
    onPress?: () => void;
    style?: CSSProperties;
    forMessage?: boolean;
}

const SmallAvatar: React.FC<Props> = ({ imageUrl, color, onPress, style, forMessage }) => {
    style = color
        ? {
              ...style,
              background: color.startsWith('#')
                  ? `linear-gradient(25deg, ${color}, #ddd)`
                  : `linear-gradient(25deg, ${'#' + color}, #ddd)`,
          }
        : { ...style, background: 'linear-gradient(25deg, #666, #ddd)' };
    if (forMessage) {
        style = { ...style, width: '30px', height: '30px', marginRight: '15px' };
    }
    return (
        <div onClick={onPress} className={styles.container} style={{ ...style }}>
            {imageUrl && <img loading="lazy" className={styles.imageBox} src={imageUrl} alt="Avatar" />}
        </div>
    );
};

export default SmallAvatar;
