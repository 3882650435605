import React from 'react';
import Header from '../../../components/Header/Header';
import { observer } from 'mobx-react-lite';
import useRootStore from '../../../hooks/useRootStore';
import MenuItem from '../../../components/MenuItem/MenuItem';
import { IoIosSettings } from 'react-icons/io';
import { Switch } from 'antd';
import styles from './Preferences.module.css';
import Text from '../../../components/Text/Text';

const Preferences = () => {
    const { closeModal } = useRootStore().routerStore;

    return (
        <div>
            <Header leftIcon="arrowLeft" text={'Privacy controll'} onLeftIconPress={() => closeModal('left')} />
            <div className={styles.settings}>
                <MenuItem
                    icon={<IoIosSettings size={24} />}
                    title={'Abusive content'}
                    right={<Switch onChange={() => {}} />}
                />
                <MenuItem
                    icon={<IoIosSettings size={24} />}
                    title={'Adult content'}
                    right={<Switch onChange={() => {}} />}
                />
                <Text
                    margin="10px 10px 5px 10px"
                    fontSize="12px"
                    textAlign={'center'}
                    children={'Control what types of messages should be visible in your feed'}
                />
            </div>
        </div>
    );
};

export default observer(Preferences);
