import ChannelsScreen from '../../screens/home/channels/ChannelsScreen';
import FriendsScreen from '../../screens/home/friendsScreen/FriendsScreen';
import AddFriend from '../../screens/home/addFriend/AddFriend';
import Account from '../../screens/home/account/Account';
import AccountSetting from '../../screens/home/accountSetting/AccountSetting';
import FriendDetail from '../../screens/home/friendDetail/FriendDetail';
import CreateChannel from '../../screens/home/createChannel/CreateChannel';
import Language from '../../screens/home/language/Language';
import EditChannel from '../../screens/home/editChannel/EditChannel';
import AddUserToChannel from '../../screens/home/addUserToChannel/AddUserToChannel';
import ManagaChannel from '../../screens/home/manageChannel/ManagaChannel';
import NewAdmin from '../../screens/home/newAdmin/NewAdmin';
import ChannelSetting from '../../screens/home/channelSetting/ChannelSetting';
import BlockUser from '../../screens/home/blockUser/BlockUser';
import ChannelInUser from '../../screens/home/channelInUser/ChannelInUser';
import CollectUsers from '../../screens/home/collectUsers/CollectUsers';
import BrandGroupChat from '../../screens/home/brandGroupChat/BrandGroupChat';
import GroupMedia from '../../screens/home/groupMedia/GroupMedia';
import GroupPolicy from '../../screens/home/groupPolicy/GroupPolicy';
import GroupPolicyManager from '../../screens/home/groupPolicyManager/GroupPolicyManager';
import BransGroupChatManager from '../../screens/home/brandGroupChatManager/BransGroupChatManager';
import BrandGroupLogoAndBack from '../../screens/home/brandGroupLogoAndBack/BrandGroupLogoAndBack';
import AddAdminToGroup from '../../screens/home/addAdminToGroup/AddAdminToGroup';
import ImportExportData from '../../screens/home/ImportExportData/ImportExportData';
import JoinRequests from '../../screens/home/joinRequests/JoinRequests';
import AddUserToChannelEdit from '../../screens/home/addUserToChannelEdit/AddUserToChannelEdit';
import JoinToChannel from '../../screens/home/joinToChannel/JoinToChannel';
import ChannelsScreenSearch from '../../screens/home/channelsSearch/ChannelsScreenSearch';
import Preferences from '../../screens/home/preferences/Preferences';

export type SideBarHelperRoutesType = {
    id: number;
    key: string;
    components: React.FC;
    isOpen: boolean;
};

export const SideBarHelperRoutes = {
    addFriends: {
        id: 1,
        key: 'addFriends',
        components: AddFriend,
        isOpen: false,
    },
    account: {
        id: 2,
        key: 'account',
        components: Account,
        isOpen: false,
    },
    settings: {
        id: 3,
        key: 'settings',
        components: AccountSetting,
        isOpen: false,
    },
    friendDetails: {
        id: 4,
        key: 'friendDetails',
        components: FriendDetail,
        isOpen: false,
    },
    language: {
        id: 5,
        key: 'language',
        components: Language,
        isOpen: false,
    },
    createChannel: {
        id: 6,
        key: 'createChannel',
        components: CreateChannel,
        isOpen: false,
    },
    channels: {
        id: 7,
        key: 'channels',
        components: ChannelsScreen,
        isOpen: false,
    },
    editChannel: {
        id: 8,
        key: 'editChannel',
        components: EditChannel,
        isOpen: false,
    },
    manageChannel: {
        id: 9,
        key: 'manageChannel',
        components: ManagaChannel,
        isOpen: false,
    },
    addUserToChannel: {
        id: 10,
        key: 'addUserToChannel',
        components: AddUserToChannel,
        isOpen: false,
    },
    newAdmin: {
        id: 11,
        key: 'newAdmin',
        components: NewAdmin,
        isOpen: false,
    },
    channelSetting: {
        id: 12,
        key: 'channelSetting',
        components: ChannelSetting,
        isOpen: false,
    },
    blockUser: {
        id: 13,
        key: 'blockUser',
        components: BlockUser,
        isOpen: false,
    },
    friends: {
        id: 14,
        key: 'friends',
        components: FriendsScreen,
        isOpen: false,
    },
    collectUsers: {
        id: 15,
        key: 'collectUsers',
        components: CollectUsers,
        isOpen: false,
    },
    brandGroupChat: {
        id: 16,
        key: 'brandGroupChat',
        components: BrandGroupChat,
        isOpen: false,
    },
    groupPolicy: {
        id: 17,
        key: 'groupPolicy',
        components: GroupPolicy,
        isOpen: false,
    },
    groupPolicyManager: {
        id: 18,
        key: 'groupPolicyManager',
        components: GroupPolicyManager,
        isOpen: false,
    },
    joinToChannel: {
        id: 19,
        key: 'joinToChannel',
        components: JoinToChannel,
        isOpen: false,
    },
    channelSearchGlobal: {
        id: 20,
        key: 'channelSearchGlobal',
        components: ChannelsScreenSearch,
        isOpen: false,
    },
    preferences: {
        id: 21,
        key: 'preferences',
        components: Preferences,
        isOpen: false,
    },
};

export const ManageHelperRoutes = {
    addFriends: {
        id: 1,
        key: 'addFriendsM',
        components: AddFriend,
        isOpen: false,
    },
    language: {
        id: 2,
        key: 'languageM',
        components: Language,
        isOpen: false,
    },
    editChannel: {
        id: 3,
        key: 'editChannelM',
        components: EditChannel,
        isOpen: false,
    },
    manageChannel: {
        id: 4,
        key: 'manageChannelM',
        components: ManagaChannel,
        isOpen: true,
    },
    addUserToChannel: {
        id: 5,
        key: 'addUserToChannelM',
        components: AddUserToChannel,
        isOpen: false,
    },
    newAdmin: {
        id: 6,
        key: 'newAdminM',
        components: NewAdmin,
        isOpen: false,
    },
    channelSetting: {
        id: 7,
        key: 'channelSettingM',
        components: ChannelSetting,
        isOpen: false,
    },
    blockUser: {
        id: 8,
        key: 'blockUserM',
        components: BlockUser,
        isOpen: false,
    },
    channelInUser: {
        id: 9,
        key: 'channelInUserM',
        components: ChannelInUser,
        isOpen: false,
    },
    groupMedias: {
        id: 10,
        key: 'groupMediasM',
        components: GroupMedia,
        isOpen: false,
    },
    groupPolicy: {
        id: 11,
        key: 'groupPolicy',
        components: GroupPolicy,
        isOpen: false,
    },
    groupPolicyManager: {
        id: 12,
        key: 'groupPolicyManager',
        components: GroupPolicyManager,
        isOpen: false,
    },
    brandGroupChat: {
        id: 13,
        key: 'brandGroupChatM',
        components: BransGroupChatManager,
        isOpen: false,
    },
    brandGroupLogoAndBack: {
        id: 14,
        key: 'brandGroupLogoAndBackM',
        components: BrandGroupLogoAndBack,
        isOpen: false,
    },
    addAdminToGroup: {
        id: 15,
        key: 'addAdminToGroup',
        components: AddAdminToGroup,
        isOpen: false,
    },
    importExportData: {
        id: 15,
        key: 'importExportData',
        components: ImportExportData,
        isOpen: false,
    },
    joinRequests: {
        id: 16,
        key: 'joinRequests',
        components: JoinRequests,
        isOpen: false,
    },
    addUserToChannelEdit: {
        id: 17,
        key: 'addUserToChannelEdit',
        components: AddUserToChannelEdit,
        isOpen: false,
    },
};
