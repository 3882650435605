import React from 'react';
import styles from '../GroupMedia.module.css';
import { RawMessage } from '../../../../types/channel';
import { Env } from '../../../../env';
import Text from '../../../../components/Text/Text';

type PropsList = {
    data: RawMessage[];
    previewImage: (value: any) => void;
};

const ImageList: React.FC<PropsList> = ({ data, previewImage }) => {
    return (
        <div className={styles.listVideo}>
            {data.length === 0 && (
                <div style={{ width: '100%' }}>
                    <Text fontSize="14px" center>
                        No Image
                    </Text>
                </div>
            )}
            {data.map(image => {
                return (
                    <div key={image.id} className={styles.listVideoItem}>
                        <div className={styles.image}>
                            <img
                                loading="lazy"
                                onClick={() => previewImage(image)}
                                src={Env.AssetsUrl + '/' + image.mediaUrl}
                                alt=""
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ImageList;
