import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Header/Header';
import MessageBox from '../../../components/MessageBox/MessageBox';
import RowItemView from '../../../components/RowItem';
import { TMP_URL } from '../../../env';
import useRootStore from '../../../hooks/useRootStore';
import styles from './AddAdmin.module.css';
import { toJS } from 'mobx';
import { useCallback, useEffect } from 'react';
import { ChannelsUsersType } from '../../../types/channel';
import ButtonView from '../../../components/Button';
import { MdAdminPanelSettings, MdCancel, MdOutlineAdminPanelSettings } from 'react-icons/md';

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
        opacity: 1,
        scale: 1,
        transition: {
            delayChildren: 0.3,
            staggerChildren: 0.2,
        },
    },
};

const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
        y: 0,
        opacity: 1,
    },
};

const AddAdminToGroup = () => {
    const { t } = useTranslation();
    const { closeModal, toRouterManageCh } = useRootStore().routerStore;
    const { addAdmin, channelData, channelUsers, getChannelUsers, hashId } = useRootStore().channelStore;
    const { user } = useRootStore().authStore;

    useEffect(() => {
        getChannelUsers(hashId);
    }, []);

    const renderAdminTitle = useCallback(
        (user: ChannelsUsersType) => {
            switch (true) {
                case channelData.adminId === user?.id:
                    return { title: t('admin'), is_admin: true, moderator: false, owner: true };
                case user?.isAdmin:
                    return { title: t('moderator'), is_admin: true, moderator: true, owner: false };
                default:
                    return { title: t('addAdmin'), is_admin: false, moderator: false, owner: false };
            }
        },
        [channelUsers, user],
    );

    const AddAdmin = async (user: ChannelsUsersType) => {
        if (renderAdminTitle(user).moderator) {
            addAdmin(channelData.hashId, { userId: user.id, isAdmin: false });
        } else if (!user.isAdmin) {
            addAdmin(channelData.hashId, { userId: user.id, isAdmin: true });
        }
    };

    return (
        <div
            style={{
                backgroundColor: '#fff',
                width: '100%',
                height: '100vh',
                overflowY: 'scroll',
            }}
        >
            <Header
                leftIcon="arrowLeft"
                text={t('Add admin')}
                onLeftIconPress={() => closeModal('right')}
                color={channelData.mainColor}
                isPremium={channelData.isPremium}
                colorPText={channelData.colorHighlight}
            />
            <div>
                <motion.div variants={container} initial="hidden" animate="visible">
                    {channelUsers.length > 0 ? (
                        channelUsers.map((e, index) => {
                            return (
                                <motion.div variants={item} key={index} id="map-dev">
                                    <RowItemView
                                        key={index}
                                        loading={false}
                                        color={e.color ? e.color : 'linear-gradient(#ddd, #666)'}
                                        imageUrl={e.avatar ? `${TMP_URL}/${e.avatar}` : ''}
                                        rightButton={!renderAdminTitle(e).is_admin}
                                        text={e.username}
                                        className={renderAdminTitle(e).owner ? styles.owner : styles.new}
                                        title={renderAdminTitle(e).moderator ? '' : renderAdminTitle(e).title}
                                        onButtonPress={() => AddAdmin(e)}
                                        btnDisabled={renderAdminTitle(e).owner}
                                        rightItem={
                                            <>
                                                {renderAdminTitle(e).moderator ? (
                                                    <div className={styles.icons}>
                                                        <button>
                                                            <MdOutlineAdminPanelSettings />
                                                        </button>
                                                        <button onClick={() => AddAdmin(e)}>
                                                            <MdCancel />
                                                        </button>
                                                    </div>
                                                ) : null}
                                                {renderAdminTitle(e).owner ? (
                                                    <div className={styles.icons}>
                                                        <button>
                                                            <MdAdminPanelSettings />
                                                        </button>
                                                    </div>
                                                ) : null}
                                            </>
                                        }
                                    />
                                </motion.div>
                            );
                        })
                    ) : (
                        <MessageBox title={`${t('No available members')}`} />
                    )}
                </motion.div>
            </div>
        </div>
    );
};

export default observer(AddAdminToGroup);
